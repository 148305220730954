<template>
  <div class="card_">
    <div>
      <h1 class="font13pt titleColor text-center pt-3">
        {{ title }}
        <tooltipComponent :tooltipText="tooltip" />
      </h1>
      <span class="d-block text-center font10pt leyendColor">{{
        subtitle
      }}</span>
    </div>
    <div class="boxInfluencer pl-4 pr-4 mt-3">
      <div
        v-for="(item, index) in topData"
        :key="item.name + index"
        class="fileInfluencer d-flex justify-space-between mb-3"
      >
        <div class="name d-flex">
          <div
            v-for="(social, indexSocial) in item.socials"
            :key="social.key + indexSocial"
          >
            <v-icon size="13" v-if="social.key === 'tw'" color="#1DA1F2">
              fab fa-twitter
            </v-icon>
            <v-icon size="13" v-if="social.key === 'fb'" color="#1877f2">
              fab fa-facebook
            </v-icon>
            <v-icon size="13" v-if="social.key === 'ig'" color="#ff69a1">
              fab fa-instagram
            </v-icon>
            <v-icon size="13" v-if="social.key === 'lk'" color="#0a66c2">
              fab fa-linkedin
            </v-icon>
            <v-icon size="13" v-if="social.key === 'nws'" color="#444444">
              fa-solid fa-newspaper
            </v-icon>
          </div>
          <div></div>
          <div class="d-flex flex-column ml-2">
            <span class="spanName d-block font12pt parColor">{{
              item.name
            }}</span>
            <span
              class="spanProfile d-block font10pt leyendColor"
              v-if="item.socials[0].key === 'tw'"
              >{{ item.profile }}</span
            >
          </div>
        </div>
        <div class="value">
          <span class="font12pt parColor font-weight-bold">
            {{ item.value }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
// import { util } from "@isc/styleguide";

export default {
  name: "Influencer",
  components: { tooltipComponent },
  props: {
    influencerAccounts: Array,
  },
  data() {
    return {
      title: "Influencer",
      subtitle: "Top 5",
      tooltip:
        "Top 5 people or accounts who have more interaction in their publications",
      topData: [],
    };
  },
  created() {
    // console.log(this.influencerAccounts);
  },
  watch: {
    influencerAccounts: {
      immediate: true,
      handler() {
        const influencerAccountsList = [];
        let influencerAccountsTotal = 0;

        this.influencerAccounts
          .slice(0, 5)
          .map(
            (item) =>
              (influencerAccountsTotal +=
                item.agg_sum_sample_interactions.value)
          );

        this.influencerAccounts.slice(0, 5).map((item) => {
          influencerAccountsList.push({
            name: item.key,
            profile: `@${item.key}`,
            value:item.agg_sum_sample_interactions.value == 0 ? 0 : ((item.agg_sum_sample_interactions.value * 100) /influencerAccountsTotal).toFixed(1),
            socials: item.agg_terms_source_snetwork_id.buckets,
          });
        });  
        this.topData = influencerAccountsList.sort((a,b) => a.value - b.value).reverse(); //sort by value
      },
    },
  },
};
</script>

<style></style>
