import "./set-public-path";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import singleSpaVue from "single-spa-vue";
import { store, vuetify } from "@isc/styleguide";
import VueApexCharts from "vue-apexcharts";
import VueWordCloud from 'vuewordcloud';
import { Timeline } from 'vue-visjs'
Vue.config.productionTip = false;

Vue.component('timeline', Timeline)
Vue.component("apexchart", VueApexCharts);
Vue.component(VueWordCloud.name, VueWordCloud);
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    el: "#overview",
    router,
    vuetify,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
