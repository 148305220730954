<template>
  <v-main>
    <v-row align-items="center">
      <v-col md="6">
        <div class="d-flex align-center mt-5">
          <h1 class="font15pt font-weight-bold mr-2">
            Emotions Daily Progression
          </h1>
          <tooltipComponent :tooltipText="tooltip" />
        </div>
        <div class="mb-5">
          <span class="d-block font10pt leyendColor">At all Social Networks</span>
        </div>
      </v-col>
      <v-col md="6" class="d-flex justify-end align-center">
        <div>
          <input class="checkbox" type="radio" id="check-gender-all" v-model="genderSelected" value="all" />
          <label for="check-gender-all" class="font12pt parColor">All</label>
        </div>
        <div>
          <input class="checkbox" type="radio" id="check-gender-male" v-model="genderSelected" value="m" />
          <label for="check-gender-male" class="font12pt parColor">Male</label>
        </div>
        <div>
          <input class="checkbox" type="radio" id="check-gender-female" v-model="genderSelected" value="f" />
          <label for="check-gender-female" class="font12pt parColor">Female</label>
        </div>
        <!-- All <input type="checkbox" name="" id=""> Positive <input type="checkbox" name="" id=""> Negative <input type="checkbox" name="" id=""> Neutral <input type="checkbox" name="" id=""> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <apexchart height="350" :options="chartOptions" :series="series"></apexchart>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";
export default {
  name: "EmotionsDailyProgression",
  components: { tooltipComponent },
  props: {
    histograms: Array,
  },
  data() {
    return {
      tooltip: "Gender by emotions according to the sample",
      male: {
        found: 6000,
        sentiment: "neutral",
        emotions: "Happy",
      },
      female: {
        found: 5000,
        sentiment: "neg",
        emotions: "Angry",
      },
      genderSelected: "all",

      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
        colors: [
          "rgb(255, 126, 126)",
          "rgb(130, 138, 150)",
          //"#9fd895",
          "rgb(255, 190, 40)",
          "rgb(107, 112, 195)",
          "#00b1f2",
          "rgb(0, 204, 192)",
          "rgb(109, 76, 65)",
        ],
        stroke: {
          curve: "smooth",
          width: 1,
        },
        xaxis: {
          tickPlacement: 'on',
          categories: [],
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function (val) {
              if (val) {
                return val.toFixed(0);
              }
            },
          },
          tickAmount: 8,
          // opposite: false,
          // showAlways: false,
          title: {
            text: "Samples",
            fontWeight: 300,
          },
        },
        markers: {
          // Tamaño de los puntos de coordenadas
          size: 4,
          hover: {
            size: 4,
          },
        },
        legend: {
          show: true,
          horizontalAlign: "right",
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          },
          markers: {
            radius: 0,
          },
        },
        // tooltip: {
        //     x: {
        //         format: 'dd/MM/yyyy'
        //     },
        // },
      },
    };
  },
  created() { },
  methods: {
  },
  watch: {
    histograms: {
      immediate: true,
      deep: true,
      handler() {
        const emotionsList = [
          {
            name: "Angry",
            data: [],
          },
          {
            name: "Neutral",
            data: [],
          },
          {
            name: "Happy",
            data: [],
          },
          {
            name: "Sad",
            data: [],
          },
          {
            name: "Fearful",
            data: [],
          },
          {
            name: "Surprised",
            data: [],
          },
          {
            name: "Disgusted",
            data: [],
          },
        ];

        const dateList = [];

        this.histograms.map((item) => {
          let emotions = item["agg_terms_sample_emotions.keyword"].buckets;

          /*  emotions = emotions.map((emotion) => {
             if (emotion.key === "neutral") {
               emotion.key = "neu";
             }
             if (emotion.key === "mad") {
               emotion.key = "ang";
             }
             if (emotion.key === "glad") {
               emotion.key = "hap";
             }
             if (emotion.key === "scared") {
               emotion.key = "fer";
             }
             return emotion;
           }); */

          emotions.map((emotion) => {
            switch (emotion.key) {
              case "ang":
                emotionsList[0].data.push(emotion.doc_count);
                break;
              case "neu":
                emotionsList[1].data.push(emotion.doc_count);
                break;
              case "hap":
                emotionsList[2].data.push(emotion.doc_count);
                break;
              case "sad":
                emotionsList[3].data.push(emotion.doc_count);
                break;
              case "fer":
                emotionsList[4].data.push(emotion.doc_count);
                break;
              case "srp":
                emotionsList[5].data.push(emotion.doc_count);
                break;
              case "dsg":
                emotionsList[6].data.push(emotion.doc_count);
                break;
            }
          });

          dateList.push(util.convertUnixToDate(item.key));
        });

        this.chartOptions.xaxis.categories = dateList;

        this.series = emotionsList;
      },
    },
    genderSelected: {
      handler() {
        const emotionsList = [
          {
            name: "Angry",
            data: [],
          },
          {
            name: "Neutral",
            data: [],
          },
          {
            name: "Happy",
            data: [],
          },
          {
            name: "Sad",
            data: [],
          },
          {
            name: "Fearful",
            data: [],
          },
          {
            name: "Surprised",
            data: [],
          },
          {
            name: "Disgusted",
            data: [],
          },
        ];

        const dateList = [];

        this.histograms.map((item) => {
          const emotions = item["agg_terms_sample_emotions.keyword"].buckets;
          //console.log(emotions);
          emotions.map((emotion) => {
            const authorCount = emotion[
              "agg_terms_author_gender.keyword"
            ].buckets.find((i) => i.key === this.genderSelected)
              ? emotion["agg_terms_author_gender.keyword"].buckets.find(
                (i) => i.key === this.genderSelected
              ).doc_count
              : 0;
            const currentCount =
              this.genderSelected === "all" ? emotion.doc_count : authorCount;
            //console.log(emotion.key, currentCount);
            switch (emotion.key) {
              case "ang":
                emotionsList[0].data.push(currentCount);
                break;
              case "neu":
                emotionsList[1].data.push(currentCount);
                break;
              case "hap":
                emotionsList[2].data.push(currentCount);
                break;
              case "sad":
                emotionsList[3].data.push(currentCount);
                break;
              case "fer":
                emotionsList[4].data.push(currentCount);
                break;
              case "srp":
                emotionsList[5].data.push(currentCount);
                break;
              case "dsg":
                emotionsList[6].data.push(currentCount);
                break;
            }
          });

          dateList.push(util.convertTimestampToDate(item.key));
        });

        this.chartOptions.xaxis.categories = dateList;
        this.series = emotionsList;
      },
    },
  },
};
</script>

<style>
.box-gender {
  width: 107px;
  height: 65px;
  border-radius: 6px;
}
</style>
