<template>
	<v-container class="pa-0">
		<v-row class="d-flex flex-row">
			<v-row class="d-flex flex-column">
				<div class="d-flex align-center mt-5">
					<h1 class="font15pt font-weight-bold mr-2">Sentiment by All Popular Terms</h1>
					<tooltipComponent :tooltipText="tooltip" />
				</div>
				<div class="mb-5">
					<span class="d-block font10pt leyendColor">On Facebook, Twitter, Instagram & Linkedin</span>
				</div>
			</v-row>
			<div class="d-flex align-center">
				<vue-multi-select
					v-model="values"
					search
					position="bottom-right"
					:options="options"
					:select-options="data"
					:btnLabel="btnLabel"
					@close="closeDriverFilter()"
					:filters="filters"
					v-if="histograms.length > 0"
				>
					<template v-slot:option="{ option }">
						<div style="font: 1em 'Oxygen' !important; font-weight: 600">
							<input type="checkbox" :checked="option.selected" />
							{{ option.name }}
						</div>
					</template>
				</vue-multi-select>
			</div>
		</v-row>
		<v-row>
      <v-col align="center">
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <apexchart ref="examplechart" height="400" :options="chartOptions" :series="series"></apexchart>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import vueMultiSelect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import { util } from "@isc/styleguide";

export default {
	name: "SentimentByAllPopularTerms",
	components: {
		tooltipComponent,
		vueMultiSelect,
	},
	props: {
		histograms: Array,
		histogramsbucket: Array,
	},
	data() {
		return {
			btnLabel: (values) => `Select an option...(${values.length})`,
			values: this.histogramsbucket,
			data: this.histogramsbucket,
			tooltip: "Sentiment generated by trends in the samples by social network",
			series: [],
			sentimentByPopular: [],
			chartOptions: {
				chart: {
					type: "bar",
					stacked: true,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				colors: ["#ff7e7e", "#828a96", "#9fd895"],
				grid: {
					show: true,
				},
				legend: {
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 0,
						columnWidth: "10%",
					},
				},
				xaxis: {
					labels: {
						datetimeUTC: true,
						show: true,
					},
					categories: [],
				},
				yaxis: {
					labels: {
						show: true,
					},
					tickAmount: 10,
					opposite: false,
					showAlways: false,
					title: {
						text: "Samples",
						fontWeight: 300,
					},
				},
				tooltip: {},
				fill: {
					opacity: 1,
				},
				stroke: {
					curve: "smooth",
				},
			},
			filters: [
				{
					nameAll: "Select all",
					nameNotAll: "Unselect all",
					func() {
						return true;
					},
				},
			],
			options: {
				multi: true,
				cssSelected: (option) =>
					option.selected
						? {
								"background-color": "#00A5FF",
								color: "#ffffff !important",
						  }
						: "",
			},
			loading: false,
		};
	},
	methods: {
		//...mapActions("ModuleGlobal", ["showDateRange", "addTitle"]),
		async closeDriverFilter() {
			this.loading = true;
			const [start, end] = this.$store.getters["global/dateRange"];
			const arraKeys = [];
			this.values.forEach((value) => {
				arraKeys.push(value.name);
			});
			const data = {
				context_id: this.$route.params.context,
				select_hashs: arraKeys.length > 0 ? arraKeys : [],
				start: null,
				end: null,
			};

			data.start = util.convertTimestampToDateFormat(start, "DD-MM-YYYY");
			data.end = util.convertTimestampToDateFormat(end, "DD-MM-YYYY");
			const {
				data: { topicHistogramMulti },
			} = await this.$store.dispatch("overview/topicHistogramMulti", data);
			this.histograms = topicHistogramMulti.histograms;
			this.loading = false;
		},
	},
	computed: {
		/* ...mapGetters('ModuleGlobal', {
            dateRange: 'dateRange'
        }) */
	},

	created() {
		this.closeDriverFilter();
		/* this.currentRangeDate = {
      startDate: util.convertTimestampToDateFormat(
        this.dateRange.startDate,
        "DD-MM-YYYY"
      ),
      endDate: util.convertTimestampToDateFormat(
        this.dateRange.endDate,
        "DD-MM-YYYY"
      ),
    }; */
	},
	watch: {
		histograms: {
			immediate: true,
			deep: true,
			handler() {
				const sentimentList = [
					{
						name: "Negative",
						data: [],
					},
					{
						name: "Neutral",
						data: [],
					},
					{
						name: "Positive",
						data: [],
					},
				];
				let cat_ = [];
				let dat_ = null;
				if (this.values.length > 0) {
					if (this.histograms.length > 0) {
						this.histograms.map((histogramItem) => {
							if (histogramItem.key) {
								dat_ = util.convertUnixToDate(histogramItem.key);

								cat_.push(dat_);

								let negative = 0;
								let positive = 0;
								let neutral = 0;
								if (histogramItem["agg_terms_sample_hashtags.keyword"].buckets) {
									histogramItem["agg_terms_sample_hashtags.keyword"].buckets.map((hashtagItem) => {
										hashtagItem["agg_terms_sample_sentiment.keyword"].buckets.map(
											(sentimentItem) => {
												switch (sentimentItem.key) {
													case "neg":
														negative += sentimentItem.doc_count;
														break;
													case "neu":
														neu += sentimentItem.doc_count;
														break;
													case "pos":
														positive += sentimentItem.doc_count;
														break;
												}
											}
										);
									});
								}
								sentimentList[0].data.push(negative);
								sentimentList[1].data.push(neutral);
								sentimentList[2].data.push(positive);
							}
						});
					}
					this.chartOptions.xaxis.categories = cat_;
					this.$refs.examplechart.updateOptions({ xaxis: { categories: cat_ } });
					this.series = sentimentList;
				} else {
					this.series = sentimentList;
				}
			},
		},
	},
};
</script>
