<template>
  <v-main class="">
    <v-row>
      <div class="
          col
          mb-5
          d-flex
          align-center          
        ">
        <div class="mr-auto">
          <h1 class="font18pt titleColor font-weight-bold" style="max-width: 120px;">
            {{ contextName }}
          </h1>
        </div>
        <div class="d-flex align-center multi-select justify-end">
          <div class="d-flex">
            <span class=" font12pt parColor">
              Filter by:
            </span>
          </div>
          <div class="d-flex">
            <div class="margin-select mx-3">
              <vue-multi-select 
                v-model="socialNetworkFilter.values" 
                :select-options="socialNetworkFilter.data"
                :options="socialNetworkFilter.options" 
                :btnLabel="socialNetworkFilter.btnLabel"
                position="bottom-right"
                @close="updateActiveGroup()"
                :disabled="socialNetworkFilter.disabled">
                <template v-slot:option="{ option }">
                  <div class="d-flex align-center pl-2" @click="getSocialNetworkFilterOption(option)">
                    <input type="checkbox" class="mr-2" :checked="option.selected" />
                    <span>{{ option.name }}</span>
                  </div>
                </template>
              </vue-multi-select>
            </div>
            <div class="multiselectContainer" v-if="driverFilter.data.length">
              <vue-multi-select v-model="driverFilter.values" :select-options="driverFilter.data"
                :options="driverFilter.options" :btnLabel="driverFilter.btnLabel" :filters="driverFilter.filters"
                position="bottom-right" emptyTabText="No drivers" @close="closeDriverBoardsFilter()">
                <template v-slot:option="{ option }">
                  <div style="font: 1em 'Oxygen' !important; font-weight: 600">
                    <input type="checkbox" :checked="option.selected" />
                    {{ option.mask || option.term || (`${option.newspaper_mask ? option.page_url : ''}`) }}
                  </div>
                </template>
              </vue-multi-select>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-end">
            <v-btn color="primary" @click="modalOpen = true" class="d-flex">
              Create boards
            </v-btn>
        </div> -->
      </div>
      <ContextModal v-if="modalOpen" @close="handleCreateModalClose" />
    </v-row>
  </v-main>
</template>

<script>
import Vue from "vue";
//import ContextModal from "@/components/context/ContextModal.vue";
import vueMultiSelect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import ContextModal from "@/components/context/CreateContextOverview.vue";

export default {
  name: "BarContextBoards",
  props: ["contextName", "contextKeywords"],
  components: {
    ContextModal,
    vueMultiSelect,
  },
  data() {
    const initialDrivers = this.contextKeywords.length
      ? this.contextKeywords.filter((keyword) => keyword.active == true)
      : [];
    return {
      initialDrivers,
      btnLabelValue: "Social Network...",
      socialNetworkFilter: {
        disabled: false,
        btnLabel: (value) => this.btnLabelValue,
        values: [],
        data: [],
        options: {
          multi: true,
          cssSelected: (option) =>
            option.selected
              ? { "background-color": "#00A5FF", color: "#ffffff !important" }
              : "",
        },
      },
      driverFilter: {
        btnLabel: (values) => `Drivers selected (${values.length})`,
        values: initialDrivers,
        data: [],
        filters: [
          {
            nameAll: "Select All",
            nameNotAll: "Unselect All",
            func() {
              return true;
            },
          },
        ],
        options: {
          multi: true,
          groups: true,
          labelList: "elements",
          groupName: "title",
          cssSelected: (option) =>
            option.selected
              ? { "background-color": "#00A5FF", color: "#ffffff !important" }
              : "",
        },
      },
      newspapers: [],
      modalOpen: false,
      refetch: new Vue(),
      updateFilter: false,
    };
  },
  async created() {
    const { data: { getNewspapers } } = await this.$store.dispatch('overview/getNewspapers')
    this.newspapers = getNewspapers

    this.driverFilter.data = this.groupDrivers();
    this.modifySocialNetworkFilter();

  },
  computed: {
    networks() {
      return this.$store.getters['global/networks'];
    }
  },
  methods: {
    getSocialNetworkFilterOption(option) {
      this.updateFilter = true

      if(option.name === 'All') { 
        if(option.selected) this.socialNetworkFilter.values = [{ name: 'All' }]
        else {

          this.socialNetworkFilter.values = []

          this.socialNetworkFilter.data.map(item => {
            if(item.name != 'All') this.socialNetworkFilter.values.push({ name: item.name })
          })
        }
      } else {
        const indexAll = this.socialNetworkFilter.values.findIndex(i => i.name === 'All')
        const data = this.socialNetworkFilter.data.filter(i => i.name != 'All')

        if(option.selected && indexAll != -1) {
          this.socialNetworkFilter.values.splice(indexAll, 1)
        }

        let values = [...this.socialNetworkFilter.values]
        if(option.selected) {
          const currentIndex = this.socialNetworkFilter.values.findIndex(i => i.name === option.name)
          values.splice(currentIndex, 1)
        } else values.push({ name: option.name })

        if(values.length === data.length ) { 
          if(indexAll == -1) this.socialNetworkFilter.values.push({ name: 'All' })
        }
      }


    },
    handleCreateModalClose: function (newContext) {
      this.modalOpen = false;
      if (newContext) {
        this.refetch.$emit("refetch");
        this.$router.push({ name: "dashboard" });
      }
    },
    emit(eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
    async closeDriverBoardsFilter() {
      //await this.emit("dataLoading", true);
      const tw_active_drivers = this.driverFilter.values
        .filter((keyword) => keyword.type == "tw")
        .map((driver) => driver.id);
      const fb_active_drivers = this.driverFilter.values
        .filter((keyword) => keyword.type == "fb")
        .map((driver) => driver.id);
      const ig_active_drivers = this.driverFilter.values
        .filter((keyword) => keyword.type == "ig")
        .map((driver) => driver.id);
      const lk_active_drivers = this.driverFilter.values
        .filter((keyword) => keyword.type == "lk")
        .map((driver) => driver.id);
      const nws_active_drivers = this.driverFilter.values
        .filter((keyword) => keyword.type == "nws")
        .map((driver) => driver.id);

      if (
        [...tw_active_drivers, ...fb_active_drivers, ...ig_active_drivers, ...lk_active_drivers, ...nws_active_drivers].length !=
        this.initialDrivers.length
      ) {
        await this.emit(
          "updateFbDrivers",
          fb_active_drivers.length > 0 ? fb_active_drivers : null
        );
        await this.emit(
          "updateTwDrivers",
          tw_active_drivers.length > 0 ? tw_active_drivers : null
        );
        await this.emit(
          "updateIgDrivers",
          ig_active_drivers.length > 0 ? ig_active_drivers : null
        );
        await this.emit(
          "updateLkDrivers",
          lk_active_drivers.length > 0 ? lk_active_drivers : null
        );
        await this.emit(
          "updateNwsDrivers",
          nws_active_drivers.length > 0 ? nws_active_drivers : null
        );
      } else {
        await this.emit("updateFbDrivers", null);
        await this.emit("updateTwDrivers", null);
        await this.emit("updateIgDrivers", null);
        await this.emit("updateLkDrivers", null);
        await this.emit("updateNwsDrivers", null);
      }
      await this.emit("closeMultiSelect");
      //await this.emit("dataLoading", false);
    },
    async updateActiveGroup() {
      if(!this.updateFilter) return;

      const listSocialNetworks = [
        { key: 'fb', title: 'Facebook' },
        { key: 'tw', title: 'Twitter' },
        { key: 'ig', title: 'Instagram' },
        { key: 'lk', title: 'Linkedin' },
      ]

      const listNewspapers = this.newspapers.map((item) => {
        return {
          key: item.newspaper_id,
          title: item.newspaper_name
        }
      })

      if (this.socialNetworkFilter.values) {
        //this.$emit("dataLoading", true);
        let tw_active_drivers = null;
        let fb_active_drivers = null;
        let ig_active_drivers = null;
        let lk_active_drivers = null;
        let nws_active_drivers = [];

        this.driverFilter.values = []

        this.socialNetworkFilter.values.forEach(item => {
          if(listSocialNetworks.some(t => t.title == item.name)) {
            switch (item.name) {
              case "Twitter":
                tw_active_drivers = this.driversIdsBySocialNetwork("tw");
                this.driverFilter.values = [...this.driverFilter.values, ...(this.contextKeywords.length
                  ? this.contextKeywords
                    .filter((keyword) => keyword.active == true)
                    .filter((keyword) => keyword.type == "tw")
                  : [])];
                break;
              case "Facebook":
                fb_active_drivers = this.driversIdsBySocialNetwork("fb");
                this.driverFilter.values = [...this.driverFilter.values, ...(this.contextKeywords.length
                  ? this.contextKeywords
                    .filter((keyword) => keyword.active == true)
                    .filter((keyword) => keyword.type == "fb")
                  : [])];
                break;
              case "Instagram":
                ig_active_drivers = this.driversIdsBySocialNetwork("ig");
                this.driverFilter.values = [...this.driverFilter.values, ...(this.contextKeywords.length
                  ? this.contextKeywords
                    .filter((keyword) => keyword.active == true)
                    .filter((keyword) => keyword.type == "ig")
                  : [])];
                break;
              case "Linkedin":
                lk_active_drivers = this.driversIdsBySocialNetwork("lk");
                this.driverFilter.values = [...this.driverFilter.values, ...(this.contextKeywords.length
                  ? this.contextKeywords
                    .filter((keyword) => keyword.active == true)
                    .filter((keyword) => keyword.type == "lk")
                  : [])];
                break;
            }
            
          } else if(listNewspapers.some(t => this.capitalize(t.title) == item.name)) {
   
            const newspaper = listNewspapers.find(t => this.capitalize(t.title) == item.name)
            nws_active_drivers = [...nws_active_drivers, ...this.driversIdsByNewspaper(newspaper.key)]

            this.driverFilter.values = [...this.driverFilter.values, ...(this.contextKeywords.length
                  ? this.contextKeywords
                    .filter((keyword) => keyword.active == true)
                    .filter((keyword) => keyword.newspaper_id == newspaper.key)
                  : [])];
          } else {

            this.driverFilter.values = this.contextKeywords.length
              ? this.contextKeywords.filter((keyword) => keyword.active == true)
              : [];
          }

        })

        this.$emit("updateFbDrivers", fb_active_drivers);
        this.$emit("updateTwDrivers", tw_active_drivers);
        this.$emit("updateIgDrivers", ig_active_drivers);
        this.$emit("updateLkDrivers", lk_active_drivers);
        this.$emit("updateNwsDrivers", nws_active_drivers);
        this.$emit("close");
        this.socialNetworkFilter.values = [];
        //this.$emit("dataLoading", false);
      }

      this.updateFilter = false
    },
    driversIdsBySocialNetwork(socialNetwork) {
      const drivers = this.contextKeywords
        .filter((keyword) => keyword.type == socialNetwork)
        .map((driver) => driver.id);
      return drivers.length > 0 ? drivers : null;
    },
    driversIdsByNewspaper(newspaperId) {
      const drivers = this.contextKeywords
        .filter((keyword) => keyword.newspaper_id == newspaperId)
        .map((driver) => driver.id);
      return drivers.length > 0 ? drivers : null;
    },
    groupDrivers() {
      const data = [];
      const types = [
        { key: 'fb', title: 'FACEBOOK' },
        { key: 'tw', title: 'TWITTER' },
        { key: 'ig', title: 'INSTAGRAM' },
        { key: 'lk', title: 'LINKEDIN' },
      ]

      this.newspapers.map((item) => {
        types.push({
          key: item.newspaper_id,
          title: item.newspaper_name
        })
      })

      if (this.contextKeywords.length) {
        types.forEach(type => {
          if (this.contextKeywords.filter((keyword) => keyword.type == type.key).length) {
            data.push({
              title: type.title,
              elements: this.contextKeywords.filter(
                (keyword) => keyword.type == type.key
              ).length
                ? this.contextKeywords.filter((keyword) => keyword.type == type.key)
                : [],
            });
          } else if(this.contextKeywords.filter((keyword) => keyword.newspaper_id == type.key).length) { // Si es newspaper
            data.push({
              title: type.title,
              elements: this.contextKeywords.filter(
                (keyword) => keyword.newspaper_id == type.key
              ).length
                ? this.contextKeywords.filter((keyword) => keyword.newspaper_id == type.key)
                : [],
            });
          }
        });
        
      }
      return data;
    },
    modifySocialNetworkFilter() {
      const groupsCount = this.driverFilter.data.length;

      for (let i = 0; i < groupsCount; i++) {
        this.socialNetworkFilter.data.push({ name: this.capitalize(this.driverFilter.data[i].title) });
      }
      if (groupsCount == 1) {
        this.socialNetworkFilter.disabled = true;
      } else {
        this.socialNetworkFilter.data.unshift({ name: "All" });
      }
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  },
  watch: {
    "driverFilter.values": {
      deep: true,
      handler() {
        const values = this.driverFilter.values;

        this.socialNetworkFilter.values = []

        const isTwitter = (item) => item.type == "tw";
        const isFacebook = (item) => item.type == "fb";
        const isInstagram = (item) => item.type == "ig";
        const isLinkedin = (item) => item.type == "lk";

        this.newspapers.map((nws) => {
          if(values.some((item) => item.newspaper_id === nws.newspaper_id)) { 
            this.socialNetworkFilter.values.push({ name: this.capitalize(nws.newspaper_name) })
          }
        })

        const isNewspapers = (item) => item.type == "nws";

        const containsTW = values.some(isTwitter);
        const containsFB = values.some(isFacebook);
        const containsIG = values.some(isInstagram);
        const containsLK = values.some(isLinkedin);
        const containsNWS = values.some(isNewspapers);
        const onlyTW = values.every(isTwitter);
        const onlyFB = values.every(isFacebook);
        const onlyIG = values.every(isInstagram);
        const onlyLK = values.every(isLinkedin);
        const onlyNWS = values.every(isNewspapers);

        if(containsTW) this.socialNetworkFilter.values.push({ name: 'Twitter' })
        if(containsFB) this.socialNetworkFilter.values.push({ name: 'Facebook' })
        if(containsIG) this.socialNetworkFilter.values.push({ name: 'Instagram' })
        if(containsLK) this.socialNetworkFilter.values.push({ name: 'Linkedin' })

        if(this.socialNetworkFilter.values.length === this.socialNetworkFilter.data.filter(i=>i.name != 'All').length ) {
          this.socialNetworkFilter.values.push({ name: 'All' })
        }


        if (containsTW && containsFB && containsIG && containsLK && containsNWS) {
          this.btnLabelValue = "All";
        } else {
          if (onlyTW) {
            this.btnLabelValue = "Twitter";
          } else {
            if (onlyFB) {
              this.btnLabelValue = "Facebook";
            } else {
              if (onlyIG) {
                this.btnLabelValue = "Instagram";
              } else {
                if (onlyLK) {
                  this.btnLabelValue = "Linkedin";
                } else {
                  if (onlyNWS) {
                    this.btnLabelValue = "Newspapers";
                  } else {
                    this.btnLabelValue = "Social Network...";
                  }
                }
              }
            }
          }
        }
      },
    },
  },
};
</script>
