<template>
	<v-container class="pa-0 mt-5 pt-5 mb-5 pb-5">
		<v-row>
			<v-col class="d-flex align-center mb-5">
				<h1 class="font15pt font-weight-bold mr-2">Sentiment By Day</h1>
				<tooltipComponent :tooltipText="tooltip" />
			</v-col>
			<v-col>
				<v-tabs v-model="tab" right>
					<v-tab>Social Networks</v-tab>
					<v-tab>Newspapers</v-tab>
				</v-tabs>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<apexchart type="bar" height="400" :options="chartOptions" :series="series"></apexchart>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";

export default {
	name: "SentimentByDay",
	components: { tooltipComponent },
	props: {
		sentiments: Object,
	},
	data() {
		return {
			tab: 0,
			tooltip: "Sentiment of the total sample shown per day according to the selected dates",
			series: [],
			newspapers: {
				series: [],
			},
			socialNetworks: {
				series: [],
			},
			chartOptions: {
				chart: {
					type: "bar",
					height: 200,
					stacked: true,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				colors: ["#828a96", "#9fd895", "#ff7e7e"],
				grid: {
					show: true,
				},
				legend: {
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0,
							},
						},
					},
				],
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 0,
						columnWidth: "10%",
					},
				},
				xaxis: {
					labels: {
						show: true,
					},
					axisTicks: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
					tickPlacement: "on",
					categories: [],
					// group: {
					//   groups: [{
					//     title: "socialNetwork",
					//     cols: 3
					//   }, {
					//     title: "newspaper",
					//     cols: 4
					//   }]
					// }
				},
				tooltip: {},
				yaxis: {
					labels: {
						show: true,
					},
					tickAmount: 10,
					opposite: false,
					showAlways: false,
					title: {
						text: "Samples",
						fontWeight: 300,
					},
				},
				fill: {
					opacity: 1,
				},
				stroke: {
					curve: "smooth",
				},
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '16px',
            fontFamily: undefined
          }
        }
			},
			sentimentsGlobal: {
				socialNetworks: {
					neutral: {
						total: 0,
						countList: [],
					},
					positive: {
						total: 0,
						countList: [],
					},
					negative: {
						total: 0,
						countList: [],
					},
				},
				newspapers: {
					neutral: {
						total: 0,
						countList: [],
					},
					positive: {
						total: 0,
						countList: [],
					},
					negative: {
						total: 0,
						countList: [],
					},
				},
			},
		};
	},
	created() {},
	methods: {
		getData() {
			const data = [];
			const dateList = [];
			const sentimentsAbbr = [
				{
					name: "pos",
					variable: "positive",
				},
				{
					name: "neutral",
					variable: "neutral",
				},
				{
					name: "neg",
					variable: "negative",
				},
			];

			this.sentiments.socialNetworksData.histograms.map((histogram) => {
				const sentimentsSocialNetworks = {
					neg: 0,
					neutral: 0,
					pos: 0,
				};

				const sentimentsNewspapers = {
					neg: 0,
					neutral: 0,
					pos: 0,
				};

				sentimentsAbbr.map((sentimentAbbr) => {
					const sentiment = histogram["agg_terms_sample_sentiment.keyword"].buckets.find(
						(i) => i.key === sentimentAbbr.name
					);
					const count = sentiment ? sentiment.doc_count : 0;

					sentimentsSocialNetworks[sentimentAbbr.name] = count;
					sentimentsNewspapers[sentimentAbbr.name] = 0;
				});

				data[histogram.key] = {
					...data[histogram.key],
					...{
						socialNetwork: sentimentsSocialNetworks,
						newspaper: sentimentsNewspapers,
					},
				};
			});

			this.sentiments.newspapersData.histograms.map((histogram) => {
				const sentimentsNewspapers = {
					neg: 0,
					neutral: 0,
					pos: 0,
				};

				sentimentsAbbr.map((sentimentAbbr) => {
					const sentiment = histogram["agg_terms_sample_sentiment.keyword"].buckets.find(
						(i) => i.key === sentimentAbbr.name
					);
					const count = sentiment ? sentiment.doc_count : 0;

					sentimentsNewspapers[sentimentAbbr.name] = count;
				});

				data[histogram.key] = {
					...data[histogram.key],
					newspaper: sentimentsNewspapers,
				};
			});

			Object.keys(data).map((key) => {
				dateList.push(util.convertUnixToDate(parseInt(key)));
				if (data[key]?.socialNetwork) {
					this.sentimentsGlobal.socialNetworks.neutral.countList.push(data[key].socialNetwork.neutral);
					this.sentimentsGlobal.socialNetworks.positive.countList.push(data[key].socialNetwork.pos);
					this.sentimentsGlobal.socialNetworks.negative.countList.push(data[key].socialNetwork.neg);
				}

				if (data[key]?.newspaper) {
					this.sentimentsGlobal.newspapers.neutral.countList.push(data[key].newspaper.neutral);
					this.sentimentsGlobal.newspapers.positive.countList.push(data[key].newspaper.pos);
					this.sentimentsGlobal.newspapers.negative.countList.push(data[key].newspaper.neg);
				}
			});

			this.chartOptions.xaxis.categories = dateList;
			this.loadSeries();
		},

		loadSeries() {
			if (!this.tab) {
				this.series = [
					{
						name: "Neutral",
						data: this.sentimentsGlobal.socialNetworks.neutral.countList,
						group: "socialNetwork",
					},
					{
						name: "Positive",
						data: this.sentimentsGlobal.socialNetworks.positive.countList,
						group: "socialNetwork",
					},
					{
						name: "Negative",
						data: this.sentimentsGlobal.socialNetworks.negative.countList,
						group: "socialNetwork",
					},
				];
			} else {
				this.series = [
					{
						name: "Neutral",
						group: "newspaper",
						data: this.sentimentsGlobal.newspapers.neutral.countList,
					},
					{
						name: "Positive",
						group: "newspaper",
						data: this.sentimentsGlobal.newspapers.positive.countList,
					},
					{
						name: "Negative",
						group: "newspaper",
						data: this.sentimentsGlobal.newspapers.negative.countList,
					},
				];
			}
		},
	},
	watch: {
		tab: {
			handler() {
				this.loadSeries();
			},
		},
		histograms: {
			immediate: true,
			deep: true,
			handler: function () {
				this.getData();
			},
		},
	},
};
</script>
