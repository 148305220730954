<template>
  <div>
    <v-main class="center-content overview_content" v-if="overviews">
      <div v-if="contextAccountId != 'geo-dashboard'">
        <Barcontext :contextName="contextName" :context-keywords="arrayKeywords" @close="refreshData"
          @closeMultiSelect="refreshData" @dataLoading="setLoader" />
      </div>
      <div v-if="contextAccountId == 'geo-dashboard'">
        <BarContextBoards :contextName="contextName" :context-keywords="arrayKeywords"
          @updateFbDrivers="updateFbDrivers" @updateTwDrivers="updateTwDrivers" @updateIgDrivers="updateIgDrivers"
          @updateLkDrivers="updateLkDrivers" @updateNwsDrivers="updateNwsDrivers" @close="refreshData()"
          @closeMultiSelect="refreshData()" @dataLoading="setLoader" />
      </div>
      <div class="overview-content__resume-cards mb-5">
        <ResumeCards :fb_active_drivers="filterData.fb_active_drivers" :tw_active_drivers="filterData.tw_active_drivers"
          :ig_active_drivers="filterData.ig_active_drivers" :lk_active_drivers="filterData.lk_active_drivers"
          :nws_active_drivers="filterData.nws_active_drivers" :filterData="filterData" :overviews="overviews"
          v-if="overviews" />
      </div>
      <div class="component-content__tabs mb-5 pb-5" style="position: relative">
        <div v-if="loadingTab">
          <v-overlay :absolute="true" :opacity="0.6" color="#f3f4f7" z-index="4">
          </v-overlay>
          <div style="position: absolute; left: 48%; top: 150px; z-index: 3">
            <v-progress-circular indeterminate size="64" color="#657185"></v-progress-circular>
          </div>
        </div>
        <v-tabs centered slider-color="#00A5FF" v-model="selectedTab" background-color="#f3f4f7" height="51" grow
          class="component-content__tabs-menu" v-on:change="changeTab">
          <v-tab key="0">
            <span class="font15pt titleColor font-weight-bold text-capitalize">Samples</span>
          </v-tab>
          <v-tab key="1">
            <span class="font15pt titleColor font-weight-bold text-capitalize">Sentiment</span>
          </v-tab>
          <v-tab key="2">
            <span class="font15pt titleColor font-weight-bold text-capitalize">Demographics</span>
          </v-tab>
          <v-tab key="3">
            <span class="font15pt titleColor font-weight-bold text-capitalize">Influencer</span>
          </v-tab>
          <v-tab key="4">
            <span class="font15pt titleColor font-weight-bold text-capitalize">Trend Topic</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab" class="overview-content__tabs__info" touchless>
          <v-tab-item transition="none" reverse-transition="fade-transition">
            <div class="tab-padding center-main extra-margin">
              <img :src="ghostImages.samples.url" style="width: 100%;" class="ghostImage"
                v-if="ghostImages.samples.active" />
              <div v-if="!ghostImages.samples.active">
                <SamplesContent :fb_active_drivers="filterData.fb_active_drivers"
                  :tw_active_drivers="filterData.tw_active_drivers" :ig_active_drivers="filterData.ig_active_drivers"
                  :lk_active_drivers="filterData.lk_active_drivers" :nws_active_drivers="filterData.nws_active_drivers"
                  :overviewSamples="overviewSamples" v-if="!loadingTab" />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item transition="none" reverse-transition="fade-transition">
            <div class="tab-padding center-main extra-margin">
              <img :src="ghostImages.sentiment.url" style="width: 100%;" class="ghostImage"
                v-if="ghostImages.sentiment.active" />
              <div v-if="!ghostImages.sentiment.active">
                <v-container>
                  <v-row>
                    <SentimentShared :sentiments="overviewSentiment" />
                  </v-row>
                  <v-row>
                    <SentimentByDay :sentiments="overviewSentiment" />
                  </v-row>
                  <v-row class="min-height-200">
                    <SentimentByChannel :networks="overviewSentiment.socialNetworksData.sourceSnetworkId" />
                  </v-row>
                  <v-row>
                    <SentimentWords :wordsData="overviewSentimentWords.words" />
                  </v-row>
                </v-container>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item transition="none" reverse-transition="fade-transition">
            <div class="tab-padding center-main extra-margin">
              <img :src="ghostImages.demographics.url" style="width: 100%;" class="ghostImage"
                v-if="ghostImages.demographics.active" />
              <div v-if="!ghostImages.demographics.active">
                <v-container>
                  <v-row>
                    <GenderSentiment :genders="overviewDemographics.gender" :emotions="overviewDemographics.emotions" />
                  </v-row>
                  <v-row>
                    <GenderDailyProgression :histograms="overviewDemographics.histograms" />
                  </v-row>
                  <v-row>
                    <MaleFemaleSentiment :histograms="overviewDemographics.histograms" />
                  </v-row>
                  <v-row>
                    <EmotionsDailyProgression :histograms="overviewDemographics.histograms" />
                  </v-row>
                </v-container>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item transition="none" reverse-transition="fade-transition">
            <div class="tab-padding center-main extra-margin">
              <img :src="ghostImages.influencer.url" style="width: 100%;" class="ghostImage"
                v-if="ghostImages.influencer.active" />
              <div v-if="!ghostImages.influencer.active">
                <InfluencersContent :influencersAccount="overviewInfluencers.influencers"
                  :fbpost="overviewInfluencers.fbpost" />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item transition="none" reverse-transition="fade-transition">
            <div class="tab-padding center-main extra-margin">
              <img :src="ghostImages.trendtopic.url" style="width: 100%;" class="ghostImage"
                v-if="ghostImages.trendtopic.active" />
              <div v-if="!ghostImages.trendtopic.active">
                <v-container>
                  <v-row class="container-row">
                    <v-col md="7" class="colTopPopularHashtags" style="padding-right: 50px">
                      <TopPopularHashtags :hashtags="overviewTrendTopics.hashtags" />
                    </v-col>
                    <v-col md="5" class="colTopTopicsCluster">
                      <TopTopicsCluster :influencerAccounts="overviewTrendTopics.influ_accounts" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <AllPopularHashtags :histograms="overviewTrendTopics.histograms" />
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: 50px">
                    <v-col>
                      <SentimentByAllPopularTerms :histograms="overviewTrendTopics.histograms"
                        :histogramsbucket="histogramsbucket" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <TopPublicationsRelatedTrendingTopic :samples="overviewTrendTopics.samples" />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-main>
  </div>
</template>

<script>
import ResumeCards from "@/components/overview/resumeCards/ResumeCards";
import SamplesContent from "@/components/overview/samples/SamplesContent";

import SentimentShared from "@/components/overview/sentiment/sentimentShared";
import SentimentByDay from "@/components/overview/sentiment/sentimentByDay";
import SentimentByChannel from "@/components/overview/sentiment/sentimentByChannel";
import SentimentWords from "@/components/overview/sentiment/sentimentWords";

import GenderSentiment from "@/components/overview/demographics/genderSentiment";
import GenderDailyProgression from "@/components/overview/demographics/genderDailyProgression";
import MaleFemaleSentiment from "@/components/overview/demographics/maleFemaleSentiment";
import EmotionsDailyProgression from "@/components/overview/demographics/emotionsDailyProgression";

import InfluencersContent from "@/components/overview/influencers/influencersContent";

import AllPopularHashtags from "@/components/overview/trendtopic/allPopularHashtags";
import TopPopularHashtags from "@/components/overview/trendtopic/topPopularHashtags";
import TopPublicationsRelatedTrendingTopic from "@/components/overview/trendtopic/topPublicationsRelatedTrendingTopic";
import SentimentByAllPopularTerms from "@/components/overview/trendtopic/sentimentByAllPopularTerms";
import TopTopicsCluster from "@/components/overview/trendtopic/topTopicsCluster";

import Barcontext from "@/components/base/barcontext";
import BarContextBoards from "@/components/base/barContextBoards";

import { util } from "@isc/styleguide";

import moment from "moment";

export default {
  name: "Overview",
  components: {
    ResumeCards,
    SamplesContent,
    SentimentShared,
    SentimentByDay,
    SentimentByChannel,
    SentimentWords,
    GenderSentiment,
    GenderDailyProgression,
    MaleFemaleSentiment,
    EmotionsDailyProgression,
    InfluencersContent,
    AllPopularHashtags,
    TopPopularHashtags,
    TopPublicationsRelatedTrendingTopic,
    SentimentByAllPopularTerms,
    TopTopicsCluster,
    Barcontext,
    BarContextBoards,
  },
  data() {
    return {
      selectedTab: null,
      loadingTab: true,
      ghostImages: {
        samples: {
          url: null,
          active: true,
        },
        sentiment: {
          url: null,
          active: true,
        },
        demographics: {
          url: null,
          active: true,
        },
        influencer: {
          url: null,
          active: true,
        },
        trendtopic: {
          url: null,
          active: true,
        },
      },
      overviews: null,
      overviewSentiment: null,
      overviewSentimentWords: null,
      overviewDemographics: null,
      overviewInfluencers: null,
      overviewTrendTopics: null,
      overviewSamples: null,
      arrayKeywords: [],
      histogramsbucket: [],
      filterData: {
        context_id: null,
        start: null,
        end: null,
        tw_active_drivers: null,
        fb_active_drivers: null,
        ig_active_drivers: null,
        lk_active_drivers: null,
        nws_active_drivers: null,
      },
    };
  },
  computed: {
    contextName() {
      return this.overviews.socialNetworksData.context_name || overviews.newspapersData.context_name
    },
    contextAccountId() {
      return this.overviews.socialNetworksData.context_account_id || overviews.newspapersData.context_account_id
    }
  },
  methods: {
    changeTab() {
      this.updateData(this.selectedTab);
    },
    async updateData(tab) {
      this.ghostImages.samples.active = true;
      this.ghostImages.sentiment.active = true;
      this.ghostImages.demographics.active = true;
      this.ghostImages.influencer.active = true;
      this.ghostImages.trendtopic.active = true;

      this.loadingTab = true;
      await this.getData(tab);
    },
    async getData(tab = 0) {
      switch (tab) {
        case 0:
          const {
            data: { samples },
          } = await this.$store.dispatch("overview/samples", this.filterData);
          this.overviewSamples = samples;
          this.ghostImages.samples.active = false;
          break;
        case 1: // SENTIMENT
          const {
            data: { sentiment },
          } = await this.$store.dispatch("overview/sentiment", this.filterData);
          const {
            data: { sentimentWords },
          } = await this.$store.dispatch(
            "overview/sentimentWords",
            this.filterData
          );
          this.overviewSentiment = sentiment;
          this.overviewSentimentWords = sentimentWords;
          this.ghostImages.sentiment.active = false;
          break;
        case 2:
          const {
            data: { demographics },
          } = await this.$store.dispatch(
            "overview/demographics",
            this.filterData
          );
          this.overviewDemographics = demographics;
          this.ghostImages.demographics.active = false;
          break;
        case 3:
          const {
            data: { influencers },
          } = await this.$store.dispatch(
            "overview/influencers",
            this.filterData
          );
          this.overviewInfluencers = influencers;
          this.ghostImages.influencer.active = false;
          break;
        case 4:
          const {
            data: { trendtopics },
          } = await this.$store.dispatch(
            "overview/trendtopics",
            this.filterData
          );
          this.overviewTrendTopics = trendtopics;

          //TODO: Refactor this
          let dateHistogram = new Object();
          let index = 0;
          let top_hash = [];
          const array = [];

          this.overviewTrendTopics.histograms.forEach(function (item) {
            let datex = moment(item.key).format("Y-MM-DD");
            let hash = item["agg_terms_sample_hashtags.keyword"].buckets;
            for (var i = 0; i < hash.length; i++) {
              let item_hash = hash[i].key;
              if (!dateHistogram.hasOwnProperty(item_hash)) {
                array.push({
                  id: index + i,
                  name: item_hash,
                  content: item_hash,
                  start: datex,
                });
                dateHistogram[item_hash] = {
                  id: index + i,
                  content: item_hash,
                  start: datex,
                };
              } else {
                dateHistogram[item_hash]["end"] = datex;
                //lista mayor a un dia
                top_hash.push(item_hash);
              }
              index = index + i;
            }
            index++;
          });
          this.histogramsbucket = array;
          this.ghostImages.trendtopic.active = false;
          break;
        default:
          break;
      }
      this.loadingTab = false;
    },
    async getOverview() {
      const { data } = await this.$store.dispatch(
        "overview/overviews",
        this.filterData
      );
      if (data == null) {
        alert("No hay drivers activos");
        this.$router.push({ name: "home" });
      } else {
        const overviews = data.overviews;
        //console.log(overviews.histograms);
        this.overviews = overviews;
        this.arrayKeywords = [];
        this.overviews.socialNetworksData.keywords.forEach((keywordItem) => {
          keywordItem.name = keywordItem.id;
          this.arrayKeywords.push(keywordItem);
        });
      }
    },
    loadImages() {
      const imgSamples = new Image();
      const imgSentiment = new Image();
      const imgDemographics = new Image();
      const imgInfluencer = new Image();
      const imgTrendtopic = new Image();
      imgSamples.onload = () => {
        this.ghostImages.samples.url = imgSamples.src;
      };
      imgSentiment.onload = () => {
        this.ghostImages.sentiment.url = imgSentiment.src;
      };
      imgDemographics.onload = () => {
        this.ghostImages.demographics.url = imgDemographics.src;
      };
      imgInfluencer.onload = () => {
        this.ghostImages.influencer.url = imgInfluencer.src;
      };
      imgTrendtopic.onload = () => {
        this.ghostImages.trendtopic.url = imgTrendtopic.src;
      };
      imgSamples.src = require("@/assets/ghost/samples.png");
      imgSentiment.src = require("@/assets/ghost/sentiments.png");
      imgDemographics.src = require("@/assets/ghost/demographics.png");
      imgInfluencer.src = require("@/assets/ghost/influencers.png");
      imgTrendtopic.src = require("@/assets/ghost/trendtopic.png");
    },
    emit(eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
    async setLoader(val) {
      await this.emit("setLoader", val);
    },
    async refreshData() {
      await this.setLoader(true);
      await this.getOverview();
      await this.updateData(this.selectedTab);
      await this.setLoader(false);
    },
    async updateTwDrivers(ids) {
      this.filterData.tw_active_drivers = ids;
    },
    async updateFbDrivers(ids) {
      this.filterData.fb_active_drivers = ids;
    },
    async updateIgDrivers(ids) {
      this.filterData.ig_active_drivers = ids;
    },
    async updateLkDrivers(ids) {
      this.filterData.lk_active_drivers = ids;
    },
    async updateNwsDrivers(ids) {
      this.filterData.nws_active_drivers = ids;
    },
    setMainData() {
      const [start, end] = this.$store.getters["global/dateRange"];
      this.filterData = {
        context_id: this.$route.params.context,
        start: null, //"01-07-2021",
        end: null, //"15-07-2021",
        tw_active_drivers: this.filterData.tw_active_drivers,
        fb_active_drivers: this.filterData.fb_active_drivers,
        ig_active_drivers: this.filterData.ig_active_drivers,
        lk_active_drivers: this.filterData.lk_active_drivers,
        nws_active_drivers: this.filterData.nws_active_drivers,
      };
      this.filterData.start = util.convertTimestampToDateFormat(
        start,
        "DD-MM-YYYY"
      );
      this.filterData.end = util.convertTimestampToDateFormat(
        end,
        "DD-MM-YYYY"
      );
    },
  },
  async mounted() {
    this.loadImages();
    this.setMainData();
    await this.getOverview();
    this.selectedTab = 0;
    await this.setLoader(false);
  },
  watch: {
    histograms: {
      deep: true,
    },
    "$store.state.global.dateRange": {
      //immediate: true,
      handler: async function () {
        this.setMainData();
        await this.refreshData();
      },
    },
  },
};
</script>

<style scoped>
.overview_content {
  margin-top: 150px;
  width: 75% !important;
}

@media screen and (min-width: 540px) and (max-width: 1024px) {
  .overview_content {
    width: 90% !important;
  }
}


.ghostImage {
  opacity: 0.3;
  filter: blur(2px);
}

@media (max-width: 1400px) {
  .container-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  .colTopPopularHashtags {
    max-width: 100%;
    width: 100%;
  }

  .colTopTopicsCluster {
    max-width: 100%;
    width: 100%;
  }
}
</style>
