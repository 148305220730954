<template>
  <v-main>
    <!-- <pie-chart :data="chartData" :options="chartOptions"/> -->
    <v-row class="d-flex flex-column mb-5">
      <div class="d-flex align-center mt-5">
        <h1 class="font15pt font-weight-bold mr-2">Top Topics Cluster</h1>
        <tooltipComponent :tooltipText="tooltip" />
      </div>
      <div class="mb-5">
        <span class="d-block font10pt leyendColor">By Volume At Facebook, Twitter, Instagram & Linkedin</span>
      </div>
    </v-row>
    <div class="chart">
      <chart-sunburst :data="tree"></chart-sunburst>
    </div>
  </v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { ChartSunburst } from "vue-d2b";

// import PieChart from '@/helpers/PieChart.js'

export default {
  name: "TopTopicsCluster",
  components: {
    tooltipComponent,
    ChartSunburst,
  },
  props: {
    influencerAccounts: Array,
  },
  data() {
    return {
      tooltip:
        "Top of words generated around the influencer according to the sample",
      color: ["#009dd1", "#00ccc0", "#6bc4fc", "#0A0A0A"],
      tree: {
        label: "Word by Influencer",
        color: "#273444",
        children: [],
      },
    };
  },
  created() {
    // console.log('carga cluster---!!', this.influencerAccounts.slice(0, 3));
  },
  methods: {
    sortBy(array, index) {
      array.sort(function (a, b) {
        if (a[index] > b[index]) {
          return 1;
        }
        if (a[index] < b[index]) {
          return -1;
        }
        return 0;
      });
      return array;
    },
  },
  computed: {
    influSorted() {
      return this.sortBy(this.influencerAccounts, "doc_count").reverse();
    },
  },
  watch: {
    influencerAccounts: {
      immediate: true,
      deep: true,
      handler() {
        const influencerAccountsList = [];
        this.influSorted.slice(0, 5).forEach((item, index) => {
          const hashtagsList = [];
          item["agg_terms_sample_words"]?.buckets.map((i) => {
            hashtagsList.push({
              label: i.key,
              size: i.doc_count,
              color: this.color[index],
            });
          });
          item["agg_terms_sample_post_words.keyword"]?.buckets.map((i) => {
            hashtagsList.push({
              label: i.key,
              size: i.doc_count,
              color: this.color[index],
            });
          });
          switch (item.agg_terms_source_snetwork_id.buckets[0].key) {
            case "tw": {
              influencerAccountsList.push({
                label: `<i class="fab fa-twitter"></i> @${item.key}`,
                children: hashtagsList,
                /* size: item.doc_count, */
                color: this.color[index],
              });
              break;
            }
            case "fb": {
              influencerAccountsList.push({
                label: `<i class="fab fa-facebook"></i> ${item.key}`,
                children: hashtagsList,
                /* size: item.doc_count, */
                color: this.color[index],
              });
              break;
            }
            case "ig": {
              influencerAccountsList.push({
                label: `<i class="fab fa-instagram"></i> ${item.key}`,
                children: hashtagsList,
                /* size: item.doc_count, */
                color: this.color[index],
              });
              break;
            }
            case "lk": {
              influencerAccountsList.push({
                label: `<i class="fab fa-linkedin"></i> ${item.key}`,
                children: hashtagsList,
                /* size: item.doc_count, */
                color: this.color[index],
              });
              break;
            }
            default: {
              influencerAccountsList.push({
                label: `<i class="fas fa-newspaper"></i> ${item.key}`,
                children: hashtagsList,
                /* size: item.doc_count, */
                color: this.color[index],
              });
            }

          }
          /* if (item.agg_terms_source_snetwork_id.buckets[0].key == "fb") {
            influencerAccountsList.push({
              label: `<i class="fab fa-facebook"></i> ${item.key}`,
              children: hashtagsList,
              //size: item.doc_count,
              color: this.color[index],
            });
          } else {
            influencerAccountsList.push({
              label: `<i class="fab fa-twitter"></i> @${item.key}`,
              children: hashtagsList,
              //size: item.doc_count,
              color: this.color[index],
            });
          } */
        });
        this.tree.children = influencerAccountsList;
      },
    },
  },
};
</script>
<style>
.chart {
  width: 100%;
  height: 350px;
}

.chart .d2b-breadcrumbs-frame {
  font-family: "Oxygen";
}

.chart .d2b-sunburst-label {
  font-size: 12px !important;
  font-family: "Oxygen" !important;
  font-weight: bold;
  color: #a2acc4;
}

.chart .d2b-sunburst-breadcrumb {
  width: 125px !important;
  margin: 0 auto;
  overflow: hidden;
}

.chart .d2b-sunburst-value {
  font-size: 14px !important;
  color: #2c3852 !important;
}

.chart .d2b-sunburst-percent {
  font-weight: bold;
}

.d2b-sunburst-value {
  display: none !important;
}

/* media query to force hide breadcrumbs when the screen is too small */
@media screen and (max-width: 500px) {
  .d2b-breadcrumbs-frame {
    display: none;
    width: 0px !important;
  }
}
</style>