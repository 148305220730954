<template>
  <v-container class="pa-0">
    <v-row class="d-flex flex-column">
      <div class="d-flex align-center mt-5">
        <h1 class="font15pt font-weight-bold mr-2">Top 5 Popular Hashtags</h1>
        <tooltipComponent :tooltipText="tooltip" />
      </div>
      <div class="mb-5">
        <span class="d-block font10pt leyendColor"
          >By Volume At Facebook, Twitter, Instagram & Linkedin</span
        >
      </div>
    </v-row>
    <v-row>
      <v-col>
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";

export default {
  name: "TopPopularHashtags",
  components: { tooltipComponent },
  props: {
    hashtags: Array,
  },
  data() {
    return {
      tooltip:
        " Top 5 hashtags generated according to the publications of the total sample according to social network ",
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 300,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ["#ff7e7e", "#828a96", "#9fd895"],
        legend: {
          show: false,
        },
       
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: "10%",
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          opposite: false,
          showAlways: false,
          title: {
            text: "Acumulated",
            // fontFamily: 'Helvetica, Arial, sans-serif',
            // fontWeight: 300,
          },
          labels: {
            show: true,
            formatter: function (val) {
              if (val) {
                return Math.round(val);
              }
            },
          },
        },
        tooltip: {},
        fill: {
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
        },
      },
    };
  },
  created() {},
  watch: {
    hashtags: {
      immediate: true,
      deep: true,
      handler() {
        const hashtagsList = [
          {
            name: "Neg",
            data: [],
          },
          {
            name: "Neutral",
            data: [],
          },
          {
            name: "Positive",
            data: [],
          },
        ];

        const hashtagsCategoriesList = [];
        this.hashtags.map((hashtagItem) => {
          let bucket =
            hashtagItem["agg_terms_sample_sentiment.keyword"].buckets;
          let statusData = true;
          let ammount_pos = 0;
          let ammount_neutral = 0;
          let ammount_neg = 0;
          for (var j = 0; j < bucket.length; j++) {

            if (bucket[j].doc_count < 0) {
              statusData = false;
            }
            if (bucket[j].key == "neg") {
              ammount_neg = bucket[j].doc_count;
            }
            if (bucket[j].key == "neutral") {
              ammount_neutral = bucket[j].doc_count;
            }
            if (bucket[j].key == "pos") {
              ammount_pos = bucket[j].doc_count;
            }
          }
          hashtagsList[0].data.push(ammount_neg);
          hashtagsList[1].data.push(ammount_neutral);
          hashtagsList[2].data.push(ammount_pos);
          if (statusData) {
            hashtagsCategoriesList.push(`#${hashtagItem.key}`);
          }
        });

        this.series = hashtagsList;
        this.chartOptions.xaxis.categories = hashtagsCategoriesList;

        const seriesArray = [
          ...new Set([
            ...hashtagsList[0].data,
            ...hashtagsList[1].data,
            ...hashtagsList[2].data,
          ]),
        ];
      },
    },
  },
};
</script>