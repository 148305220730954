<template>
  <div class="context-box" style="width: 90%;">
    <v-row no-gutters>
      <v-col :sm="12" :md="12" :lg="12">
        <v-row no-gutters>
          <v-col cols="12"><span class="font12pt leyendColor">What:</span></v-col>
        </v-row>
      </v-col>
      <v-col :sm="6" :md="7" :lg="8">
        <v-row>
          <v-col cols="12" class="pr-1 pl-1 pt-1">
            <input maxlength="80" type="text" class=" input pl-3 font12pt titleColor font-weight-bold w-100"
              placeholder="Paste Url Fan Page..." v-model="newDriverPage" />
            <div class="pt-1 textError ml-0" v-if="warningText != ''">
              <span>{{ warningText }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col :sm="6" :md="5" :lg="4">
        <div class="d-flex justify-end align-end">
          <v-btn depressed color="bgColorPrincipal" class="mainBtn mx-2" :disabled="buttonDisabled"
            @click="addNewDriver()">
            <span class="white--text">Create</span>
          </v-btn>
          <v-btn depressed color="bgColorPrincipal" class="mainBtn" @click="openModal = true">
            <span class="white--text mr-1" style="font-size: 20px;">+</span><span class="white--text"> ISC
              Drivers</span>
          </v-btn>
          <!-- <button class="pt-2 mt-1 pb-2 pr-5 pl-5 mr-2 bgColorPrincipal rounded" v-bind:class="{ buttonDisabled }"
            @click="addNewDriver()" :disabled="buttonDisabled">
            <span class="pl-2 pr-2 white--text"> Create </span>
          </button>
          <button class="pt-2 mt-1 pb-2 pr-3 pl-3 bgColorPrincipal rounded" @click="openModal = true">
            <span class="white--text"> <b>+</b> ISC Drivers </span>
          </button> -->
        </div>
      </v-col>
      <v-row class="context-box__table">
        <v-col>
          <div class="context-box__table__header d-flex">
            <div class="col-12">
              <span class="font12pt leyendColor">Fan Page list</span>
            </div>
          </div>
          <div class="bgColorBase context-box__table__body">
            <v-virtual-scroll :bench="10" :items="allDrivers" max-height="168" item-height="42">
              <template v-slot:default="{ item }">
                <v-row v-bind:style="{
                  backgroundColor: item.geo_dashboard ? '#fbfbfb' : 'inherit',
                }">
                  <v-col cols="5">
                    <div style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                      <span class="font12pt titleColor ml-3">
                        {{ item.name }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" align="start">
                    <div style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                      <span class="font12pt leyendColor">
                        {{ `${item.mask}` }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" align="end">
                    <div style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                      <span class="font12pt leyendColor" v-if="item.likes">
                        {{ ` ${formatLikes(item.likes)} Likes` }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="1"> </v-col>
                  <v-col cols="1" align="start">
                    <div>
                      <v-icon @click="deleteDriver(item)" size="11" color="#a2acc4">fas fa-trash</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="1" align="start">
                    <div>
                      <v-icon v-if="item.active === true" size="12" color="#00a5ff" @click="item.active = false">fas
                        fa-eye</v-icon>
                      <v-icon v-if="item.active === false" size="12" color="#a2acc4" @click="item.active = true">fas
                        fa-eye</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </v-virtual-scroll>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <AddIscDriverModal v-if="openModal" @closeIscDriverModal="closeIscDriverModal" :driverType="'fb'" />
  </div>
</template>


<script>
import AddIscDriverModal from "@/components/context/AddIscDriverModal.vue";
export default {
  name: "CreateFacebookDrivers",
  components: { AddIscDriverModal },
  props: ["fbDriversToAdd", "allIscDriversToAdd"],
  data() {
    return {
      drivers: [],
      newDriverPage: "",
      openModal: false,
      warningText: "",
      selectedISCDrivers: [],
      buttonDisabled: true,
      paises: [
        {
          id: "all",
          name: "All (Around the world)",
        },
        {
          id: "2dfa9ecb0179a4e4",
          name: "Perú",
        },
        {
          id: "47a3cf27863714de",
          name: "Chile",
        },
        /* {
          id: "0639360bd49a15e3",
          name: "Colombia",
        }, */
      ],
    };
  },
  created() { },
  methods: {
    addNewDriver: async function () {
      this.buttonDisabled = true;
      this.$emit("driverLoading", true);
      const urlRegEx =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
      if (!this.newDriverPage || !this.newDriverPage.match(urlRegEx)) {
        this.showWarning("Invalid URL");
        this.$emit("driverLoading", false);
        return;
      }
      try {
        await this.sendUrlCheck();
      } catch (error) {
        if (this.newDriverPage.includes("-")) {
          const splitUrl = this.newDriverPage.split("-");
          const pageId = splitUrl[splitUrl.length - 1];
          this.newDriverPage = `https://www.facebook.com/${pageId}`;
          try {
            await this.sendUrlCheck();
          } catch (error) {
            this.showWarning("URL invalid");
          }
        } else {
          this.showWarning("URL invalid");
        }
      } finally {
        this.$emit("driverLoading", false);
      }
    },
    sendUrlCheck: async function () {
      try {
        this.buttonDisabled = true;
        const {
          data: { fbFanpageCheck },
        } = await this.$store.dispatch("overview/fanpageCheck", {
          fanpage_url: this.newDriverPage.trim(),
        });
        if (fbFanpageCheck && fbFanpageCheck.public === true) {
          const newDriver = {
            page_url: this.newDriverPage.trim(),
            page_id: fbFanpageCheck.id,
            mask: fbFanpageCheck.mask,
            active: true,
            likes: fbFanpageCheck.likes,
            name: fbFanpageCheck.name,
          };
          if (this.fbDriversToAdd.some((d) => d.page_id == newDriver.page_id)) {
            this.showWarning("Fanpage already added");
          } else {
            this.drivers.push(newDriver);
            //delete newDriver.likes;
            this.newDriverPage = "";
            this.$emit("driversToAdd", newDriver);
          }
        } else {
          throw new Error("Error al validar Fanpage");
        }
      } catch (error) {
        this.buttonDisabled = true;
        console.log(error);
        throw error;
      }
    },
    closeIscDriverModal(driver) {
      this.openModal = false;
      if (driver) {
        if (this.selectedISCDrivers.some((d) => d.mask == driver.mask)) {
          this.showWarning("Driver already added");
        } else {
          this.selectedISCDrivers.push({
            ...driver,
            added: new Date().valueOf(),
          });
          this.$emit("iscDriversToAdd", driver);
        }
      }
    },
    showWarning: function (message) {
      this.warningText = message;
      setTimeout(() => (this.warningText = ""), 3000);
    },
    deleteDriver: function (driver) {
      if (driver.id) {
        this.selectedISCDrivers = this.selectedISCDrivers.filter(
          (d) => d.id != driver.id
        );
        this.$emit("deleteIscDriversToAdd", driver);
      } else {
        const driverIndex = this.drivers.findIndex((d) => {
          return d.term == driver.term && d.place_id == driver.place_id;
        });
        this.drivers.splice(driverIndex, 1);
        this.$emit("deleteDriversToAdd", driver);
      }
    },
    formatLikes(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + " T";
    },
  },
  computed: {
    allDrivers: function () {
      return [...this.selectedISCDrivers, ...this.drivers].sort(
        (a, b) => a.added - b.added
      );
    },
  },
  watch: {
    newDriverPage: {
      immediate: true,
      handler: function (x) {
        if (this.newDriverPage.trim().length > 0) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      },
    },
  },
};
</script>

<style>
.v-main__fbDriver {
  padding: 0px 0px 0px 0px !important;
}

.w570 {
  width: 470px;
}
</style>