import Router from "vue-router";
import OverView from "@/views/Overview.vue";
export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/overview/:context",
      name: "overview",
      component: OverView,
    },
    {
      path: "/overview",
      redirect: "/dashboard/your-boards",
    },
  ],
});
