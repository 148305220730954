<template>
  <div class="card_ d-flex justify-space-between flex-column">
    <div>
      <h1 class="font13pt titleColor text-center pt-3">
        {{ title }}
        <tooltipComponent :tooltipText="tooltip" />
      </h1>
      <span class="d-block text-center font10pt leyendColor">{{
          subtitle
      }}</span>
    </div>
    <div class="genderBox pl-4 pr-4 d-flex justify-space-between align-center">
      <div class="d-flex flex-column justify-center aling-center">
        <span class="font37pt font-weight-thin ColorMale">
          {{ currentGender.male.perc }} <span class="dgraph_card_percentage">%</span>
        </span>
        <div class="d-flex justify-space-around align-center"><span class="dgraph_card_percentage_xs"
            style="color:#00b1f2">%</span>
          <v-icon size="12" color="#00b1f2">fas fa-mars</v-icon>
        </div>
      </div>
      <!-- <div class="d-flex flex-column justify-center aling-center dgraph_card_percentage_xs" style="display: none;">
        <span class="font-weight-thin" style="font-size: 15pt;">%</span>
      </div> -->
      <div class="d-flex flex-column justify-center aling-center">
        <span class="font37pt font-weight-thin ColorFemale">
          {{ currentGender.female.perc }} <span class="dgraph_card_percentage">%</span>
        </span>
        <div class="d-flex justify-space-around align-center"><span class="dgraph_card_percentage_xs"
            style="color:#ff9a9a">%</span>
          <v-icon size="12" color="#ff9a9a">fas fa-venus</v-icon>
        </div>
      </div>
    </div>
    <div class="infoGender text-center">
      <span class="font12pt leyendColor">
        {{ currentGender.other.perc }} % {{ currentGender.other.name }}
      </span>
    </div>
    <div class="genderInfo d-flex justify-space-between">
      <div class="infoGenderBox bgMale d-flex justify-center align-content-center">
        <span class="font15pt font-weight-bold text-uppercase">{{
            currentGender.male.value
        }}</span>
      </div>
      <div class="infoGenderBox bgFemale d-flex justify-center aling-center">
        <span class="font15pt font-weight-bold text-uppercase">{{
            currentGender.female.value
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
export default {
  name: "Demographics",
  components: { tooltipComponent },
  props: ["gender"],
  data() {
    return {
      title: "Demographics",
      subtitle: "Unique Authors",
      tooltip: "Predominant gender quantity according to total sample",
      currentGender: {
        male: {
          value: 0,
          perc: 0,
        },
        female: {
          value: 0,
          perc: 0,
        },
        other: {
          perc: 0,
          name: "Unknown",
        },
      },
      malePerc: 0,
    };
  },
  methods: {
    convertNumberTokFormat(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    genderCount(network) {
      let femaleCount = 0
      let maleCount = 0
      let unknownCount = 0

      if(network) {
        femaleCount = network.f ? network.f.count : 0
        maleCount = network.m ? network.m.count : 0
        unknownCount = network.u ? network.u.count : 0
      }

      return {
        femaleCount,
        maleCount,
        unknownCount
      }
    }
  },
  watch: {
    gender: {
      immediate: true,
      deep: true,
      handler: function (val, oldVal) {
        const genderCountSocialNetwork = this.genderCount(this.gender.socialNetworks)
        const genderCountNewspaper = this.genderCount(this.gender.newspapers)
        
        const femaleCount = genderCountSocialNetwork.femaleCount + genderCountNewspaper.femaleCount;
        const maleCount = genderCountSocialNetwork.maleCount + genderCountNewspaper.maleCount;
        const unknownCount = genderCountSocialNetwork.unknownCount + genderCountNewspaper.unknownCount;

        const totalCountGender = femaleCount + maleCount + unknownCount;
        const percentFemale = (femaleCount * 100) / totalCountGender;
        const percentMale = (maleCount * 100) / totalCountGender;
        const percentUnknown = (unknownCount * 100) / totalCountGender;

        this.currentGender.male.value =
          this.convertNumberTokFormat(maleCount) || 0;
        this.currentGender.female.value =
          this.convertNumberTokFormat(femaleCount) || 0;

        this.currentGender.male.perc = Math.round(percentMale.toFixed(2)) || 0;
        this.currentGender.female.perc =
          Math.round(percentFemale.toFixed(2)) || 0;
        this.currentGender.other.perc =
          Math.round(percentUnknown.toFixed(2)) || 0;
      },
    },
  },
};
</script>

<style>
.dgraph_card_percentage_xs {
  display: none;
}

@media screen and (min-width: 540px) and (max-width: 1024px) {
  .dgraph_card_percentage {
    display: none;
  }

  .dgraph_card_percentage_xs {
    display: block;
  }
}
</style>