<template>
  <div id="overview">
    <v-app>
      <v-main class="bgColorBase">
        <div class="main-container">
        <Loader v-if="loading"/>
        <router-view @setLoader="setLoader"/>
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
// import { querys } from "@isc/styleguide";
import Loader from "./components/base/loader.vue";
import { mapActions } from "vuex";
export default {
  components: {
    Loader
  },
  data(){
    return{
      loading: true,
    }
  },
  created() {
    this.addTitle("Overview");
    this.disabledMenuItem(false);
    this.$store.dispatch("global/setDatePickerActive", true);
    this.loadNetworks()
  },
  methods: {
    ...mapActions("global", ["addTitle", "disabledMenuItem", 'loadNetworks']),
    setLoader(val){
      this.loading = val;
    }
    // sendGraphQL() {
    //   querys.testing();
    // },
  },
};
</script>
<style>
.cars {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
