<template>
  <div class="d-flex justify-space-between">
    <SamplesCard :histogramsSourceQuery="histogramsSourceQuery" />
    <SentimentCard :histograms="histograms" />
    <DemographicsCard :gender="gender" />
    <InfluencerCard :influencerAccounts="influ_accounts" />
    <TrendTopicCard :topics="words" />
  </div>
</template>

<script>
import SamplesCard from "./SamplesCard";
import SentimentCard from "./SentimentCard";
import DemographicsCard from "./DemographicsCard";
import InfluencerCard from "./InfluencerCard";
import TrendTopicCard from "./TrendTopicCard";

//import { mapActions, mapGetters } from "vuex";
import { util } from "@isc/styleguide";

export default {
  name: "ResumeCards",
  props: ["filterData", "overviews"],
  components: {
    SamplesCard,
    SentimentCard,
    DemographicsCard,
    InfluencerCard,
    TrendTopicCard,
  },
  data() {
    return {
      overviewResume: null,
      currentRangeDate: {
        startDate: null,
        endDate: null,
      },
      histograms: null,
      histogramsSourceQuery: null,
      gender: null,
      influ_accounts: null,
      source_query: null,
      words: null,
    };
  },
  async created() {
    /* await this.loadOverviewResume(); */
  },
  methods: {
    //...mapActions("ModuleGlobal", ["showDateRange"]),
    /* async loadOverviewResume() {
      this.overviewResume = this.overviews;
    }, */
  },
  watch: {
    overviews: {
      immediate: true,
      deep: true,
      handler: function(){
        const { socialNetworksData, newspapersData} = this.overviews
        this.overviewResume = this.overviews;
        this.histograms = { socialNetworks: socialNetworksData.histograms, newspapers: newspapersData.histograms }
        this.histogramsSourceQuery = {
            socialNetworks: socialNetworksData.histogramsSourceQuery,
            newspapers: newspapersData.histogramsSourceQuery 
        }
        this.gender = { socialNetworks: this.overviews.socialNetworksData.gender, newspapers: this.overviews.newspapersData.gender};
        this.influ_accounts = [...this.overviews.socialNetworksData.influencers, ...this.overviews.newspapersData.influencers];
        this.source_query = this.overviews.socialNetworksData.source_query;
        this.words = [...this.overviews.socialNetworksData.words, ...this.overviews.newspapersData.words];
      }
    }
  }
};
</script>

<style>
/* .card_ {
  width: 234px;
  height: 275px;
  background-color: #fff;
} */

.card_ {
  width: 19%;
  height: 275px;
  background-color: #fff;
}


.score {
  padding-top: 50px;
  padding-bottom: 30px;
}
.genderBox {
  padding-top: 30px;
  /* padding-bottom: 30px; */
}
.infoGender {
  padding: 1.3em;
}
.genderInfo {
  padding: 0 3px;
}
.infoGenderBox {
  height: 46px;
  width: 110px;
  border-radius: 5px 5px 0 0;
  color: #fff;
  padding-top: 13px;
}
/* .title_card{

  } */
</style>
