<template>
  <div class="card_" style="position: relative;">
    <h1 class="font13pt titleColor text-center pt-3">
      {{ title }}
      <tooltipComponent :tooltipText="tooltip" />
    </h1>

    <div class="card-charts">
    

      <template  v-for="item in [socialNetworks, newspapers]">

      <div class="card-content position-relative" :key="item.name">
      
        <div class="score d-flex justify-center flex-column align-center pt-2 pb-12">
          <span class="subti font10pt leyendColor">
              {{ item.name }}
            </span>
          <div class="value_score">
            <span class="font15pt font-weight-normal titleColor text-uppercase">
              {{ item.score.value }}
            </span>
          </div>
          <div
            class="
              info_score
              d-flex
              align-center 
              align-content-space-between
              flex-column
            "
          >
            <span class="subti font10pt leyendColor">
              {{ item.score.subtitle }}
            </span>
            <span
              class="sentiment font10pt text-capitalize"
              :class="item.score.sentiment"
            >
              {{ item.score.sentiment }}
            </span>
          </div>
        </div>

        <div class="st-graph" style="position: absolute;bottom: 0;padding: 0 5% 0 5% !important">

          <apexchart
            type="bar"
            height="40"
            :options="chartOptions"
            :series="item.series"
          ></apexchart>
        </div> 
      
      </div>
          
      </template>

    </div>
  </div>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";
export default {
  name: "SentimentCard",
  components: { tooltipComponent },
  data() {
    return {
      title: "Sentiment",
      tooltip: "Amount of predominant sentiment according to total sample",
      series: [],
      socialNetworks: {
        color: '#ff9a9a',
        name: 'Social Networks',
        series: [],
        score: {
          value: 0,
          sentiment: "positive",
          subtitle: "Mostly",
        },
      },
      newspapers: {
        color: '#00b1f2',
        name: 'Newspapers',
        series: [],
        score: {
          value: 0,
          sentiment: "positive",
          subtitle: "Mostly",
        }
      },
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          sparkline: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ["#828a96", "#9fd895", "#ff7e7e"],
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: "60%",
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
            datetimeUTC: true,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {},
        yaxis: {
          labels: {
            show: false,
          },
          opposite: true,
          showAlways: false,
        },
        fill: {
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
        },
      },
    };
  },
  props: {
    histograms: Array,
  },
  created() {
    //this.onInit();
  },
  methods: {
    getData(histograms) {
      if(!histograms) return;
      const sentiments = {
        neutral: {
          total: 0,
          countList: [],
        },
        positive: {
          total: 0,
          countList: [],
        },
        negative: {
          total: 0,
          countList: [],
        },
      };


      histograms.map((i) => {
        const buckets = i["agg_terms_sample_sentiment.keyword"].buckets;
        const date = i.key;

        const negCount = buckets.some(s => s.key === 'neg') ? buckets.find(s => s.key === 'neg').doc_count : 0
        const neutralCount = buckets.some(s => s.key === 'neutral') ? buckets.find(s => s.key === 'neutral').doc_count : 0
        const posCount = buckets.some(s => s.key === 'pos') ? buckets.find(s => s.key === 'pos').doc_count : 0

        sentiments.negative.total += negCount;
        sentiments.negative.countList.push([date, negCount]);

        sentiments.neutral.total += neutralCount;
        sentiments.neutral.countList.push([date,neutralCount]);

        sentiments.positive.total += posCount;
        sentiments.positive.countList.push([date,posCount]);
      });

      const totalToShow = 8

      // for(let i=0;i< histograms.length; i++) {
      //   sentiments.negative.countList.push(0);
      //   sentiments.neutral.countList.push(0);
      //   sentiments.positive.countList.push(0);
      // } 

        const series = [
          {
            name: "Neutral",
            data: sentiments.neutral.countList,
          },
          {
            name: "Positive",
            data: sentiments.positive.countList,
          },
          {
            name: "Negative",
            data: sentiments.negative.countList,
          },
        ];

        const maxSentiment = util.maxFromArray([
          sentiments.neutral.total,
          sentiments.positive.total,
          sentiments.negative.total,
        ]);

        const score = {
          value: 0,
          sentiment: "positive",
          subtitle: "Mostly",
        }

        switch (maxSentiment) {
          case sentiments.neutral.total:
            score.sentiment = "neutral";
            break;
          case sentiments.positive.total:
            score.sentiment = "positive";
            break;
          case sentiments.negative.total:
            score.sentiment = "negative";
            break;
        }

        score.value = util.convertNumberTokFormat(maxSentiment);

        return {
          series,
          score,
          isReady: true
        }
    }
  },
  watch: {
    histograms: {
      immediate: true,
      handler() {
        const socialNetworks = this.getData(this.histograms.socialNetworks)
        this.socialNetworks = {...this.socialNetworks, ...socialNetworks}

        const newspapers = this.getData(this.histograms.newspapers)
        this.newspapers = {...this.newspapers, ...newspapers}
      },
    },
  },
};
</script>

<style></style>
