<template>
  <div v-if="overviewSample">
    <SamplesGraphic
      :histograms="histograms"
      :mentions="mentions"
      :averageSamples="average_samples"
    />

    <SamplesDrivers :drivers="source_query" />
  </div>
</template>

<script>
import SamplesGraphic from "@/components/overview/samples/SamplesGraphic";
import SamplesDrivers from "@/components/overview/samples/SamplesDrivers";

import { util } from "@isc/styleguide";

export default {
  name: "SamplesContent",
  components: { SamplesGraphic, SamplesDrivers },
  props: ["tw_active_drivers", "fb_active_drivers", "overviewSamples"],
  components: { SamplesGraphic, SamplesDrivers },
  data() {
    return {
      overviewSample: null,
      /* currentRangeDate: {
        startDate: null,
        endDate: null,
      }, */
      histograms: null,
      mentions: null,
      average_samples: null,
      source_query: null,
    };
  },
  async created() {
    /* await this.loadOverviewSamples(); */
  },
  methods: {
    /* async loadOverviewSamples() {
      this.overviewSample = this.overviewSamples;
    }, */
  },
  watch: {
    overviewSamples: {
      immediate: true,
      deep: true,
      handler: function(){
        const { socialNetworksData, newspapersData} = this.overviewSamples
        
        this.overviewSample = this.overviewSamples.socialNetworksData;
        this.histograms = { socialNetworks: socialNetworksData.histograms, newspapers: newspapersData.histograms };
        this.mentions = this.overviewSamples.socialNetworksData.mentions + this.overviewSamples.newspapersData.mentions;
        this.average_samples = this.overviewSamples.socialNetworksData.average_samples + this.overviewSamples.newspapersData.average_samples;
        this.source_query = { socialNetworks: socialNetworksData.source_query, newspapers: newspapersData.source_query };
      }
    }
  }
};
</script>
