<template>
	<v-container class="pa-0">
		<v-row class="d-flex flex-column">
			<div class="d-flex align-center mt-5">
				<h1 class="font15pt font-weight-bold mr-2">Top 10 Publications related Trending Topic</h1>
				<tooltipComponent :tooltipText="tooltip1" />
			</div>
			<div class="mb-5">
				<span class="d-block font10pt leyendColor"
					>At Facebook, Twitter, Instagram & Linkedin <tooltipComponent :tooltipText="tooltip2" :tooltipSize="10"
				/></span>
			</div>
		</v-row>
		<div class="table--general">
			<v-data-table
				:headers="headers"
				:items="samplesList"
				:hide-default-header="false"
				:hide-default-footer="true"
				:light="true"
				class=""
			>
				<template v-slot:[`item.postOrTweet`]="{ item }" class="text-center">
					<span class="font12 d-block span-table pt-2 pb-2">
						{{ item.postOrTweet }}
					</span>
				</template>
				<template v-slot:[`item.user`]="{ item }" class="text-center">
					<span class="font12 d-block span-table text-truncate" style="max-width: 150px">
						{{ item.user }}
					</span>
				</template>
				<template v-slot:[`item.socialNetwork`]="{ item }" class="text-center">
					<v-icon v-if="item.socialNetwork.includes('fb')" small class="mr-1" color="#598ec2">
						fab fa-facebook
					</v-icon>
					<v-icon v-if="item.socialNetwork.includes('tw')" small class="mr-1" color="#1da1f2">
						fab fa-twitter
					</v-icon>
					<v-icon v-if="item.socialNetwork.includes('ig')" small class="mr-1" color="#ff69a1">
						fab fa-instagram
					</v-icon>
					<v-icon v-if="item.socialNetwork.includes('lk')" small class="mr-1" color="#0a66c2">
						fab fa-linkedin
					</v-icon>
				</template>
			</v-data-table>
			<!-- <table class="table">
                <thead>
                    <tr>
                        <th>Social Network</th>
                        <th>Interactions</th>
                        <th>User</th>
                        <th>Post / Tweet</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="sample in samplesList" :key="sample.key">
                    <td>{{ sample.socialNetwork }}</td>
                    <td>{{ sample.interactions }}</td>
                    <td>{{ sample.user | socialNetworkType(sample.socialNetwork) }}</td>
                    <td>{{ sample.postOrTweet }}</td> 
                    </tr>
                </tbody>
            </table> -->
		</div>
	</v-container>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";

export default {
	name: "TopPublicationsRelatedTrendingTopic",
	components: { tooltipComponent },
	props: {
		samples: Array,
	},
	data() {
		return {
			tooltip1: "Publications related by popular terms according to social network.",
      tooltip2: `Interactions only available for Facebook & Instagram - User not available for Facebook (Privacy policy)`,
			samplesList: [],
			headers: [
				{
					text: "Social network",
					align: "center",
					value: "socialNetwork",
					class: ["font-weight-bold", "titleColor", "font13pt"],
					width: "100px",
				},
				{
					text: "Interactions",
					align: "center",
					value: "interactions",
					class: ["font-weight-bold", "titleColor", "font13pt"],
				},
				{
					text: "User",
					align: "left",
					value: "user",
					class: ["font-weight-bold", "titleColor", "font13pt"],
				},
				{
					text: "Post / Tweet",
					align: "left",
					value: "postOrTweet",
					class: ["font-weight-bold", "titleColor", "font13pt"],
				},
			],
		};
	},
	created() {},
	watch: {
		samples: {
			immediate: true,
			deep: true,
			handler() {
				const samplesList = [];

				this.samples.map((sampleItem, key) => {
					samplesList.push({
						key: `sm_${key}`,
						socialNetwork: sampleItem.agg_terms_source_snetwork_id.buckets[0].key,
						interactions: sampleItem.agg_sum_sample_interactions.value,
						user: sampleItem["agg_terms_author_screen_name.keyword"].buckets[0].key,
						postOrTweet: sampleItem.key,
					});
				});

				this.samplesList = samplesList;
			},
		},
	},
	mounted() {
		//console.log("lista", this.samplesList);
	},
};
</script>
