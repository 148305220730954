<template>
  <div class="card_">
    <div>
      <h1 class="font13pt titleColor text-center pt-3">
        {{ title }}
        <tooltipComponent :tooltipText="tooltip" />
      </h1>
      <span class="d-block text-center font10pt leyendColor">{{
        subtitle
      }}</span>
    </div>
    <div class="boxInfluencer pl-4 pr-4 mt-3">
      <div v-for="item in topData" :key="item.name" class="fileInfluencer d-flex mb-3">
        <div class="name d-flex mr-auto">
          <div class="d-flex flex-column ml-2">
            <span class="spanName d-block font12pt parColor">{{
              item.name
            }}</span>
            <span class="spanProfile d-block font10pt leyendColor">{{
              item.profile
            }}</span>
          </div>
        </div>
        <div class="value d-flex">
          <v-icon size="13" color="#1DA1F2" v-if="item.tw" class="mr-1">
            fab fa-twitter
          </v-icon>
          <v-icon size="13" color="#1877f2" v-if="item.fb" class="mr-1">
            fab fa-facebook
          </v-icon>
          <v-icon size="13" color="#ff69a1" v-if="item.ig" class="mr-1">
            fab fa-instagram
          </v-icon>
          <v-icon size="13" color="#0a66c2" v-if="item.lk" class="mr-1">
            fab fa-linkedin
          </v-icon>
          <v-icon size="13" color="#444444" v-if="item.nws" class="mr-1">
            fa-solid fa-newspaper
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
export default {
  name: "TrendTopicCard",
  components: { tooltipComponent },
  props: {
    topics: Array,
  },
  data() {
    return {
      title: "Trend Topic",
      subtitle: "Top 5",
      tooltip:
        "Top 5 words generated according to the publications of the total sample",
      topData: [
        /* {
          netWork: "tw",
          name: "influencer1",
          profile: "influencer",
          value: 30,
        },
        {
          netWork: "tw",
          name: "influencer2",
          profile: "influencer",
          value: 40,
        },
        {
          netWork: "tw",
          name: "influencer3",
          profile: "influencer",
          value: 50,
        },
        {
          netWork: "tw",
          name: "influencer4",
          profile: "influencer",
          value: 30,
        },
        {
          netWork: "tw",
          name: "influencer5",
          profile: "influencer",
          value: 20,
        }, */
      ],
    };
  },
  created() {
  },
  watch: {
    topics: {
      immediate: true,
      handler() {
        const topicsList = [];
        if(this.topics) {
          this.topics.map((item) => {
            const socialNetworks = item.agg_terms_source_snetwork_id.buckets.map(
              (data) => {
                return { key: data.key, doc_count: data.doc_count };
              }
            );
            topicsList.push({
              name: item.key,
              profile: item.doc_count,
              value: 20,
              fb: socialNetworks.some(data => data.key == 'fb' && data.doc_count > 0) ? true : false,
              tw: socialNetworks.some(data => data.key == 'tw' && data.doc_count > 0) ? true : false,
              ig: socialNetworks.some(data => data.key == 'ig' && data.doc_count > 0) ? true : false,
              lk: socialNetworks.some(data => data.key == 'lk' && data.doc_count > 0) ? true : false,
              nws: socialNetworks.some(data => data.key == 'nws' && data.doc_count > 0) ? true : false,
            });
          });
        }

        this.topData = topicsList.splice(0, 5);
      },
    },
  },
};
</script>

<style></style>