<template>
    <v-main>
        <div class="modalcontent elevation-2 pa-3" style="background-color: white;">
            <div class="modalcontent__title pb-4 position-relative">
                <h2 class="font15pt titleColor font-weight-bold"
                    style="text-overflow: ellipsis;white-space: nowrap;max-width: 25rem;overflow: hidden;">{{
                        contentModal.word }}</h2>
                <span class="leyendColor font10pt">Top 3 Tweets / Post / News</span>
                <div class="close-modal-btn position-absolute" @click="closeModalWord()">
                    <v-icon color="#37afef">
                        fas fa-times-circle
                    </v-icon>
                </div>
            </div>
            <div class="modalcontent__content">
                <div class="modalcontent__content__item pr-3">
                    <div v-for="(item, index) in contentModal.modalcontent" :key="index" class="mb-5 pb-3 border_bt">
                        <div class="modalcontent__content__user d-flex">
                            <v-avatar size="40">
                                <img alt="user" :src="item.userAvatar"
                                    @error="$event.target.src = 'https://listen-images.s3.us-east-2.amazonaws.com/user-image.png'">
                            </v-avatar>
                            <div class="modalcontent__content__userinfo d-flex flex-column justify-end pl-2">
                                <div>
                                    <span class="font12pt font-weight-bold">
                                        {{ item.userName }}
                                    </span>
                                    <span> </span><!-- blank space -->
                                    <span class="ColorMale font10pt" v-if="item.socialNetwork != 'nws'">
                                        <i v-if="item.socialNetwork == 'tw'">@</i>{{ item.userName }}
                                    </span>
                                </div>
                                <div class="leyendColor font10pt">
                                    <span class="">
                                        {{ item.postDate }}
                                    </span>
                                    <span class="" v-if="item.socialNetwork != 'nws'">
                                        - {{ item.postTime }} hours
                                    </span>
                                </div>
                            </div>
                            <div class="modalcontent__content__icon ml-auto d-flex justify-center">
                                <v-icon v-if="item.socialNetwork == 'fb'" small color="#1877f2">
                                    fab fa-facebook
                                </v-icon>
                                <v-icon small v-if="item.socialNetwork == 'tw'" color="#1da1f2">
                                    fab fa-twitter
                                </v-icon>
                                <v-icon small v-if="item.socialNetwork == 'ig'" color="#ff69a1">
                                    fab fa-instagram
                                </v-icon>
                                <v-icon small v-if="item.socialNetwork == 'lk'" color="#0a66c2">
                                    fab fa-linkedin
                                </v-icon>
                                <v-icon small v-if="item.socialNetwork == 'nws'" color="#444444">
                                    fa-solid fa-newspaper
                                </v-icon>
                            </div>
                        </div>
                        <div class="modalcontent__content__comments mt-2">
                            <div v-if="item.is_rtw" class="modalcontent__content__comments__rtw-user">
                                <span class="pl-1 font10pt leyendColor">retweeted of </span><span
                                    class="font10pt ColorMale">@{{ item.retweetProfile }}</span>
                            </div>
                            <div class="modalcontent__content__comments__text pt-2">
                                <span class="parColor font12pt modal_text_item">
                                    {{ item.postcontent }}
                                </span>
                            </div>
                            <div v-if="item.postImage" class="modalcontent__content__comments__image mt-5">
                                <v-img :src="item.postImage"></v-img>
                            </div>
                            <div class="modalcontent__content__comments__reactions d-flex mt-5 mb-2">
                                <div v-if="item.socialNetwork === 'fb' || item.socialNetwork === 'lk'"
                                    class="modalcontent__content__comments__reactions__item">
                                    <v-icon size="10" color="#a2acc4">
                                        fas fa-thumbs-up
                                    </v-icon>
                                    <span class="leyendColor font10pt pl-1">{{ item.likeCount }}</span>
                                </div>
                                <div v-if="item.socialNetwork == 'fb' || item.socialNetwork == 'lk' || item.socialNetwork == 'ig'"
                                    class="modalcontent__content__comments__reactions__item pl-2">
                                    <v-icon size="10" color="#a2acc4">
                                        fas fa-share
                                    </v-icon>
                                    <span class="leyendColor font10pt pl-1">{{ item.sharedCount }}</span>
                                </div>
                                <div v-if="item.socialNetwork == 'fb' || item.socialNetwork == 'lk' || item.socialNetwork == 'ig'"
                                    class="modalcontent__content__comments__reactions__item pl-2">
                                    <v-icon size="10" color="#a2acc4">
                                        fas fa-comments
                                    </v-icon>
                                    <span class="leyendColor font10pt pl-1">{{ item.comentsCount }}</span>
                                </div>
                                <div v-if="item.socialNetwork == 'tw'"
                                    class="modalcontent__content__comments__reactions__item pl-2">
                                    <v-icon size="10" color="#a2acc4">
                                        fas fa-retweet
                                    </v-icon>
                                    <span class="leyendColor font10pt pl-1">{{ item.retweetCount }}</span>
                                </div>
                                <div v-if="item.socialNetwork == 'tw' || item.socialNetwork == 'ig'"
                                    class="modalcontent__content__comments__reactions__item pl-2">
                                    <v-icon size="10" color="#a2acc4">
                                        fas fa-heart
                                    </v-icon>
                                    <span class="leyendColor font10pt pl-1">{{ item.likeCount }}</span>
                                </div>
                            </div>
                            <!-- <div v-if="item.coments" class="modalcontent__content__comments__post-comments">
                                <div class="modalcontent__content__comments__post-comments__item mb-2 pr-3 pl-3 pt-4 pb-4">
                                    <div class="modalcontent__content__comments__post-comments__date titleColor font10pt">
                                        06-06-2021 - 11:20 hours
                                    </div>
                                    <div
                                        class="modalcontent__content__comments__post-comments__text mt-2 parColor font12pt">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus harum nam
                                        officiis a, eum autem? Officiis consectetur quam.
                                    </div>
                                </div>
                                <div class="modalcontent__content__comments__post-comments__item mb-2 pr-3 pl-3 pt-4 pb-4">
                                    <div class="modalcontent__content__comments__post-comments__date titleColor font10pt">
                                        06-06-2021 - 11:20 hours
                                    </div>
                                    <div
                                        class="modalcontent__content__comments__post-comments__text mt-2 parColor font12pt">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus harum nam
                                        officiis a, eum autem? Officiis consectetur quam.
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-main>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'WordModal',
    props: ['contentModal'],
    data() {
        return {

        }
    },
    mounted() {
        let els = document.getElementsByClassName('modal_text_item');
        let word = this.contentModal.word;
        this.higlightWords(els, word)
        // console.log('modal', this.contentModal.modalcontent);
    },
    methods: {
        ...mapActions('global', [
            'closeModalWord'
        ]),
        higlightWords(els, word) {
            els = Array.prototype.slice.call(els, 0);
            let regex = '';
            let regex1 = '';
            let regex3 = '';
            let mapElement = els.map((x) => {
                regex = new RegExp("(" + "[^a-zA-Z]" + word + "[^a-zA-Z]" + ")", "ig");
                regex1 = new RegExp("(" + "[^a-zA-Z]" + word + ")$", "ig");
                regex3 = new RegExp("^(" + word + "[^a-zA-Z]" + ")", "ig");
                if (regex.test(x.innerText)) this.changeInnertext(x, regex)
                if (regex1.test(x.innerText)) this.changeInnertext(x, regex1)
                if (regex3.test(x.innerText)) this.changeInnertext(x, regex3)
            })

        },
        changeInnertext(el, rex) {
            const result = el.innerText.match(rex)[0];
            const filtered_result = `${result.charAt(0)}<span class='highlight'>${result.substring(1, result.length)}</span>`
            el.innerHTML = el.innerText.replace(rex, filtered_result);
        }
    }
}
</script>

<style></style>
