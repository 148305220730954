<template>
	<div>
		<v-row class="d-flex justify-center">
			<v-col>
				<v-row>
					<v-col md="6">
						<div class="bg-gray d-flex align-center flex-column bgColorBase py-3 rounded-lg">
							<div class="d-flex">
								<span class="font12pt parColor font-weight-light mr-1">Samples</span>
								<tooltipComponent :tooltipText="'Total sample according to the selected date'" />
							</div>
							<h1 class="font31pt titleColor font-weight-black text-center">
								{{ mentions }}
							</h1>
						</div>
					</v-col>

					<v-col md="6">
						<div class="bg-gray d-flex align-center flex-column bgColorBase py-3 rounded-lg">
							<div class="d-flex">
								<span class="font12pt parColor font-weight-light mr-1">Avg / Day</span>
								<tooltipComponent
									:tooltipText="'Average of the samples according to the selected date'"
								/>
							</div>
							<h1 class="font31pt titleColor font-weight-black text-center">
								{{ average }}
							</h1>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col md="12">
				<v-row class="mt-5 align-center">
					<v-col cols="auto">
						<div class="d-flex align-center">
							<h1 class="font15pt font-weight-bold mr-2">Samples by day</h1>
						</div>
					</v-col>
					<v-col class="d-flex justify-end">
						<v-checkbox
							v-for="(network, index) in networks"
							:key="index"
							:label="network.name"
							:ripple="false"
							v-model="filter"
							:value="network.name"
							hide-details
							dense
							@change="updateData"
							class="checkbox-filter ml-3"
						></v-checkbox>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="graphsamples">
				<apexchart type="area" height="350" :options="chartOptions" :series="series" ref="chart"></apexchart>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";

export default {
	name: "SamplesGraphic",
	components: { tooltipComponent },
	/* props: {
    histograms: {
      type: Array,
      default() {
        return [];
      },
    },
    mentions: Number,
    averageSamples: Number,
  }, */
	props: ["histograms", "mentions", "averageSamples"],
	data() {
		return {
			tooltip: "Gender by sentiment and emotions.",
			filter: [],
			male: {
				found: 6000,
				sentiment: "neutral",
				emotions: "glad",
			},
			female: {
				found: 5000,
				sentiment: "neg",
				emotions: "angry",
			},
			chartOptions: {
				chart: {
					height: 350,
					type: "area",
					width: "100%",
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				colors: ["#1DA1F2", "#1877f2", "#ff69a1", "#0a66c2", "#444444"],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
					width: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: true,
					// },
					categories: [],
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						show: true,
						formatter: function (val) {
							if (val) {
								return Math.round(val);
							}
						},
					},
				},
				min: 0,
				max: 10,
				tickAmount: 1,
				tooltip: {
					x: {
						format: "dd/MM",
					},
				},
				legend: {
					horizontalAlign: "right",
					markers: {
						radius: 0,
					},
					onItemClick: {
						toggleDataSeries: false,
					},
				},
				fill: {
					opacity: 0.4,
					type: "solid",
				},
			},
			average: null,
			series: [],
			newspapers: [],
		};
	},
	computed: {
		networks() {
			return this.$store.getters["global/networks"];
		},
	},
	created() {
		this.setData2();

		this.filter = this.networks.map((i) => {
			return i.name;
		});
	},
	methods: {
		async setData() {
			this.average = this.averageSamples ? Math.round(this.averageSamples) : 0;

			const data = [];

			const networksList = this.networks.map((i) => {
				return {
					...i,
					data: [],
				};
			});

			if (this.histograms.socialNetworks) {
				this.histograms.socialNetworks.map((sn) => {
					networksList.map((network) => {
						const socialNetwork = sn.agg_terms_source_snetwork_id.buckets.find(
							(i) => i.key === network.key
						);
						const countNetwork = socialNetwork ? socialNetwork.doc_count : 0;

						data[sn.key] = { ...data[sn.key], [network.key]: countNetwork };
					});
				});
			}

			if (this.histograms.newspapers) {
				this.histograms.newspapers.map((sn) => {
					networksList.map((network) => {
						const newspapers = sn["agg_terms_source_query.keyword"].buckets.find(
							(i) => i.newspaper_id === network.key
						);
						const countNetwork = newspapers ? newspapers.doc_count : 0;

						data[sn.key] = { ...data[sn.key], [network.key]: countNetwork };
					});
				});
			}

			const dateList = [];

			let seriesArray = [];

			Object.keys(data).map((key) => {
				dateList.push(util.convertUnixToDate(parseInt(key)));

				networksList.map((i) => {
					i.data.push(data[key][i.key]);
				});
			});

			networksList.map((i) => {
				seriesArray = new Set([...seriesArray, ...i.data]);
			});

			this.chartOptions.xaxis.categories = dateList;

			this.series = networksList.map((i) => {
				return {
					name: i.name,
					data: i.data,
				};
			});

			this.chartOptions.yaxis.min = 0;
			this.chartOptions.yaxis.max = Math.max(...seriesArray);
			if (seriesArray.length > 1) {
				delete this.chartOptions.yaxis.tickAmount;
			}
		},
		async setData2() {
			this.average = this.averageSamples ? Math.round(this.averageSamples) : 0;
			const networksList = this.networks.map((i) => {
				return {
					...i,
					data: [],
				};
			});

			let allDates = [];
			for (const network of networksList) {
				if (network.type == "socialNetwork") {
					if (this.histograms?.socialNetworks) {
						this.histograms.socialNetworks.map((snetwork) => {
							allDates.push(parseInt(snetwork.key));
						});
					}
				} else {
					if (this.histograms?.newspapers) {
						this.histograms.newspapers.map((snetwork) => {
							allDates.push(parseInt(snetwork.key));
						});
					}
				}
			}
			allDates = [...new Set(allDates)].sort();
			for (const date of allDates) {

				const dateExistsSocialNetwork = this.histograms.socialNetworks.find((i) => i.key === date);
				if (!dateExistsSocialNetwork) {
					networksList.map((network) => {
						if (network.type == "socialNetwork") {
							network.data.push(0);
						}
					});
				}else{
					networksList.map((network) => {
						if (network.type == "socialNetwork") {
							const socialNetwork = dateExistsSocialNetwork.agg_terms_source_snetwork_id.buckets.find(
								(i) => i.key === network.key
							);
							if (socialNetwork) {
								network.data.push(socialNetwork.doc_count);
							} else {
								network.data.push(0);
							}
						}
					});
				}

				const dateExistsNewspaper = this.histograms.newspapers.find((i) => i.key === date);
				if (!dateExistsNewspaper) {
					networksList.map((network) => {
						if (network.type == "newspaper") {
							network.data.push(0);
						}
					});
				}else{
					networksList.map((network) => {
						if (network.type == "newspaper") {
							const newspaper = dateExistsNewspaper["agg_terms_source_query.keyword"].buckets.find(
								(i) => i.newspaper_id === network.key
							);
							if (newspaper) {
								network.data.push(newspaper.doc_count);
							} else {
								network.data.push(0);
							}
						}
					});
				}
			}
			this.chartOptions.xaxis.categories = allDates.map((i) => {
				return util.convertUnixToDate(i);
			});

			this.series = networksList.map((i) => {
				return {
					name: i.name,
					data: i.data,
				};
			});
		},
		updateData() {
			this.networks.map((network) => {
				if (this.filter.some((i) => i === network.name)) this.$refs.chart.showSeries(network.name);
				else this.$refs.chart.hideSeries(network.name);
			});
		},
	},
	watch: {
		histograms: {
			immediate: true,
			deep: true,
			handler: function () {
				this.setData2();
			},
		},
	},
};
</script>

<style>
.box-gender {
	width: 107px;
	height: 65px;
	border-radius: 6px;
}

.graphsamples .apexcharts-legend {
	padding-top: 20px !important;
}
</style>
