<template>
	<div class="card_" style="position: relative">
		<h1 class="font13pt titleColor text-center pt-3">
			{{ title }}
			<tooltipComponent :tooltipText="tooltip" />
		</h1>

		<div class="score d-flex flex-column align-center pt-8">
			<div class="d-flex flex-row mb-5">
				<div class="legend legend-basic">
					<div class="legend-basic__icon" style="background: #ff9a9a"></div>
					<div class="legend-basic__text">Social Networks</div>
				</div>

				<div class="legend legend-basic">
					<div class="legend-basic__icon" style="background: #00b1f2"></div>
					<div class="legend-basic__text">Newspapers</div>
				</div>
			</div>

			<div class="d-flex">
				<div class="value_score">
					<span class="font37pt font-weight-thin titleColor text-uppercase">
						{{ summatory }}
					</span>
				</div>

				<div class="info_score d-flex align-stretch align-content-space-between flex-column">
					<span class="sentiment_score">
						<v-icon
							:color="
								socialNetworks.totalHistogramsSamples > newspapers.totalHistogramsSamples
									? socialNetworks.color
									: newspapers.color
							"
						>
							fas fa-caret-down
						</v-icon>
					</span>
					<span class="perc_esocre leyendColor font10pt"> {{ score.perc }}% </span>
				</div>
			</div>
		</div>

		<div class="" style="position: absolute; bottom: 0; padding: 0 5% 0 5% !important">
			<!-- graphic here -->
			<apexchart
				type="area"
				height="125"
				:options="chartOptionsSamples"
				:series="[
					{
						name: 'Social Networks',
						data: this.socialNetworks.histogramsList,
						color: '#ff9a9a',
					},
					{
						name: 'Newspapers',
						data: this.newspapers.histogramsList,
						color: '#00b1f2',
					},
				]"
			>
			</apexchart>
		</div>
	</div>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";
export default {
	name: "SamplesCard",
	components: { tooltipComponent },
	props: ["histogramsSourceQuery"],
	data() {
		return {
			title: "Samples",
			tooltip: "Total sample by day according to selected date",
			score: {
				value: "12.90K",
				sentiment: "pos",
				perc: 0,
			},
			summatory: 0,
			socialNetworks: {
				color: "#ff9a9a",
				histogramsList: [],
				totalHistogramsSamples: 0,
			},
			newspapers: {
				color: "#00b1f2",
				histogramsList: [],
				totalHistogramsSamples: 0,
			},
			chartOptionsSamples: {
				chart: {
					type: "area",
					width: "100%",
					sparkline: {
						enabled: true,
					},
					zoom: {
						enabled: false,
					},
					toolbar: {
						show: false,
					},
				},
				grid: {
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
					width: 1,
					colors: ["#ff9a9a", "#00b1f2"],
				},
				xaxis: {
					type: "datetime",
					labels: {
						show: false,
						datetimeUTC: true,
					},
					axisTicks: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						show: false,
						formatter: function (val) {
							if (val) {
								return Math.round(val);
							}
						},
					},
					opposite: true,
					showAlways: false,
				},
				legend: {
					horizontalAlign: "left",
				},
			},
		};
	},
	created() {
		this.setData();
		//this.onInit(this.histograms);
	},
	methods: {
		calcPercentageDiff(val1, val2) {
			const max = Math.max(val1, val2);
			const min = Math.min(val1, val2);
			const diff = max - min;
			const percDiff = (diff / max) * 100;
			return percDiff;
		},
		convertNumberToKmFormat(num) {
			if (Math.abs(num) > 999999) {
				return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "m";
			} else if (Math.abs(num) > 999) {
				return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k";
			} else {
				return Math.sign(num) * Math.abs(num);
			}
		},
		setData() {
			let countSocialNetworks = 0;
			let countNewspapers = 0;
			this.histogramsSourceQuery?.socialNetworks.map((item) => {
				countSocialNetworks += item.doc_count;
			});
			this.histogramsSourceQuery?.newspapers.map((item) => {
				countNewspapers += item.doc_count;
			});
			this.socialNetworks.totalHistogramsSamples = countSocialNetworks;
			this.newspapers.totalHistogramsSamples = countNewspapers;
			this.summatory = this.convertNumberToKmFormat(
				this.socialNetworks.totalHistogramsSamples + this.newspapers.totalHistogramsSamples
			);
			this.score.perc = this.calcPercentageDiff(
				this.socialNetworks.totalHistogramsSamples,
				this.newspapers.totalHistogramsSamples
			).toFixed(2);
			let allDates = [];
			const socialNetworks = [];
			const newspapers = [];
			if (this.histogramsSourceQuery?.socialNetworks) {
				this.histogramsSourceQuery.socialNetworks.map((item) => {
					allDates.push(item.key);
				});
			}
			if (this.histogramsSourceQuery?.newspapers) {
				this.histogramsSourceQuery.newspapers.map((item) => {
					allDates.push(item.key);
				});
			}
			allDates = [...new Set(allDates)].sort();
			for (const date of allDates) {
				const dateExistsSocialNetworks = this.histogramsSourceQuery.socialNetworks.find((i) => i.key === date);
				if (!dateExistsSocialNetworks) {
					socialNetworks.push([date, 0]);
				} else {
					socialNetworks.push([date, dateExistsSocialNetworks.doc_count]);
				}
				const dateExistsNewspapers = this.histogramsSourceQuery.newspapers.find((i) => i.key === date);
				if (!dateExistsNewspapers) {
					newspapers.push([date, 0]);
				} else {
					newspapers.push([date, dateExistsNewspapers.doc_count]);
				}
			}
			this.socialNetworks.histogramsList = [];
			this.newspapers.histogramsList = [];
			this.socialNetworks.histogramsList = socialNetworks;
			this.newspapers.histogramsList = newspapers;
		},
	},
	computed: {
		/* totalHistogramSamplesFormated: function () {
      return this.convertNumberTokFormat(this.totalHistogramsSamples);
    }, */
	},
	watch: {
		histogramsSourceQuery: {
			immediate: true,
			deep: true,
			handler: function () {
				this.setData();
				// const socialNetworks = [];
				// if (this.histograms.socialNetworks) {
				// 	this.histograms.socialNetworks.map((item) => {
				// 		socialNetworks.push(item.doc_count);
				// 	});
				// }

				// const histogramsInfo = [];
				// for (const histograms of this.histograms.socialNetworks) {
				// 	histogramsInfo.push({
				// 		key: histograms.key,
				// 		type: "socialNetworks",
				// 		value: histograms.doc_count,
				// 	});
				// }
				// for (const histograms of this.histograms.newspapers) {
				// 	histogramsInfo.push({
				// 		key: histograms.key,
				// 		type: "newspapers",
				// 		value: histograms.doc_count,
				// 	});
				// }
				// this.socialNetworks.histogramsList = histogramsInfo
				// 	.filter((item) => item.type === "socialNetworks")
				// 	.map((item) => {
				// 		return [item.key, item.value];
				// 	});

				// this.newspapers.histogramsList = histogramsInfo
				// 	.filter((item) => item.type === "newspapers")
				// 	.map((item) => {
				// 		return [item.key, item.value];
				// 	});

				// if (this.socialNetworks.histogramsList.length) {
				// 	this.socialNetworks.totalHistogramsSamples = this.convertNumberToKmFormat(
				// 		this.socialNetworks.histogramsList.reduce((total, actual) => {
				// 			return total + actual[1];
				// 		}, 0)
				// 	);
				// } else {
				// 	this.socialNetworks.totalHistogramsSamples = 0; /* this.convertNumberTokFormat(0) */
				// }

				// const newspapers = [];
				// if (this.histograms.newspapers) {
				// 	this.histograms.newspapers.map((item) => {
				// 		newspapers.push(item.doc_count);
				// 	});
				// }

				// if (this.newspapers.histogramsList.length) {
				// 	this.newspapers.totalHistogramsSamples = this.convertNumberToKmFormat(
				// 		(this.newspapers.totalHistogramsSamples = this.convertNumberToKmFormat(
				// 			this.newspapers.histogramsList.reduce((total, actual) => {
				// 				return total + actual[1];
				// 			}, 0)
				// 		))
				// 	);
				// } else {
				// 	this.newspapers.totalHistogramsSamples = 0; /* this.convertNumberTokFormat(0) */
				// }
			},
		},
	},
};
</script>

<style></style>
