<template>
  <v-main class="pt-5 mt-5">
    <v-row>
      <v-col class="d-flex align-center mb-5">
        <h1 class="font15pt font-weight-bold mr-2">Words Sentiment</h1>
        <tooltipComponent :tooltipText="tooltip" />
      </v-col>
    </v-row>
    <v-row>
      <div class="sentimentword col pa-0 d-flex justify-space-between">
        <div class="d-flex">
          <div class="sentimentword__box-gradient"></div>
          <div
            class="
              sentimentword__box-gradient_names
              d-flex
              flex-column
              justify-space-between
              pl-2
            "
          >
            <span class="titleColor">Positive</span>
            <span class="titleColor">Neutral</span>
            <span class="titleColor">Negative</span>
          </div>
        </div>
        <div class="sentimentword__cloud position-relative">
          <div class="sentimentword__cloud__box position-absolute">
            <vue-word-cloud
              v-if="cloudWords"
              :words="cloudWords[0]"
              style="height: 250px;width: 100%;position-relative;z-index:1"
              font-family="Oxygen"
              class="font-base"
            >
              <template slot-scope="{ text, weight, word }">
                <div
                  :ref="word[3]"
                  :title="weight"
                  :class="{
                    positive: word[2] === 'pos',
                    negative: word[2] === 'neg',
                    neutral: word[2] === 'neutral',
                  }"
                  style="cursor: pointer"
                  class="font-base position-relative"
                  @click="onWordClick(word[3])"
                >
                  {{ text }}
                </div>
              </template>
            </vue-word-cloud>
          </div>
          <WordModal
            v-if="modalOpen"
            class="modal_component position-absolute white"
            :contentModal="modalData"
          />
        </div>
        <div class="sentimentword__table table--general">
          <v-data-table
            :headers="headers"
            :items="itemsTable"
            :hide-default-header="false"
            :hide-default-footer="true"
            :items-per-page="-1"
            :light="true"
            class="elevation-1"
          >
            <template v-slot:[`item.type`]="{ item }">
              <v-icon
                v-if="item.type.includes('tw')"
                small
                color="#1da1f2"
              >
                fab fa-twitter
              </v-icon>
              <v-icon
                v-if="item.type.includes('fb')"
                small
                class="mr-1"
                color="#1877f2"
              >
                fab fa-facebook
              </v-icon>              
              <v-icon
                v-if="item.type.includes('ig')"
                small
                class="mr-1"
                color="#ff69a1"
              >
                fab fa-instagram
              </v-icon>
              <v-icon
                v-if="item.type.includes('lk')"
                small
                class="mr-1"
                color="#0a66c2"
              >
                fab fa-linkedin
              </v-icon>
              <v-icon
                v-if="item.type.includes('nws')"
                small
                class="mr-1"
                color="#444444"
              >
                fa-solid fa-newspaper
              </v-icon>
            </template>
            <template v-slot:[`item.sentiment`]="{ item }">
              <v-icon v-if="item.sentiment == 'neutral'" small color="#828a96">
                far fa-hand-point-right
              </v-icon>
              <v-icon v-if="item.sentiment == 'pos'" small color="#9fd895">
                far fa-thumbs-up
              </v-icon>
              <v-icon v-if="item.sentiment == 'neg'" small color="#ff7e7e">
                far fa-thumbs-down
              </v-icon>
            </template>
            <template v-slot:[`item.word`]="{ item }">
              <div class="text-truncate" style="max-width: 120px">
                {{ item.word }}
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-row>
  </v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import WordModal from "@/components/overview/sentiment/wordModal.vue";
import { mapActions, mapGetters } from "vuex";
import VueWordCloud from "vuewordcloud";
import { util, store } from "@isc/styleguide";

export default {
  name: "SentimentWords",
  components: {
    tooltipComponent,
    [VueWordCloud.name]: VueWordCloud,
    WordModal,
  },
  props: {
    wordsData: Array,
  },
  computed: {
    ...mapGetters("global", {
      modalOpen: "getOpenModal",
    }),
  },
  data() {
    return {
      tooltip:
        "Sentiment of the words generated by the selected drivers according to the chosen date.",
      cloudWords: [],
      wordsDT: [],
      modalData: {},
      headers: [
        {
          text: "Words",
          align: "left",
          value: "word",
          class: ["font-weight-bold", "titleColor", "font13pt"],
          width: "120",
        },
        {
          text: "Type",
          align: "center",
          value: "type",
          sortable: false,
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
          width: "50",
        },
        {
          text: "Sentiment",
          align: "center",
          value: "sentiment",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
          width: "90",
        },
        {
          text: "Interactions",
          align: "center",
          value: "interactions",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
          width: "100",
        },
      ],
      itemsTable: [],
    };
  },
  mounted() {
    /* this.setWords();
    this.setItemsTable(); */
  },
  methods: {
    ...mapActions("global", ["openModalWord", "closeModalWord"]),
    onWordClick(e) {
      this.modalData = this.wordsDT.find((x) => x.id == e);
      if (this.modalData.modalcontent.length !== 0 || undefined) this.openModalWord();
    },
    setWords() {
      this.wordsDT = [];
      this.wordsDT = this.wordsData.map((item, index) => {
        let netWorkIcons = [];
        netWorkIcons = item.agg_terms_source_snetwork_id.buckets.map(
          (x) => x.key
        );
        let dataTW = item.agg_terms_source_snetwork_id.buckets.filter(
          (x) => x.key == "tw"
        );
        let dataFB = item.agg_terms_source_snetwork_id.buckets.filter(
          (x) => x.key == "fb"
        );
        let dataIG = item.agg_terms_source_snetwork_id.buckets.filter(
          (x) => x.key == "ig"
        );
        let dataLK = item.agg_terms_source_snetwork_id.buckets.filter(
          (x) => x.key == "lk"
        );
        let dataNWS = item.agg_terms_source_snetwork_id.buckets.filter(
          (x) => x.key == "nws"
        );
        let max_sentiment = util.maxFromObjectArray(
          item["agg_terms_sample_sentiment.keyword"].buckets,
          "doc_count"
        );
        let fbModal;
        let fb_post;
        let fb_post_data;
        // console.log('word algo', dataIG);

        let twModal;
        let tw_post;
        let tw_post_data;

        let nwsModal;
        let nws_post;
        let nws_post_data;
        
        let igModal;
        let ig_post;
        let ig_post_data;
        
        let lkModal;
        let lk_post;
        let lk_post_data;

        let mdCnt = [];
        if (dataTW.length !== 0) {
          tw_post = dataTW;
          tw_post_data = tw_post.map(
            (e) => e["agg_terms_sample_text.keyword"].buckets
          );
          twModal = tw_post_data[0].slice(0, 3).map((i) => {
            let profile = i["agg_terms_author_screen_name.keyword"].buckets[0]
              ? i["agg_terms_author_screen_name.keyword"].buckets[0].key
              : "unknow";
            let post_item_is_retweet =
              i.agg_terms_sample_is_rt.buckets[0].key_as_string == "true"
                ? i.agg_terms_sample_is_rt.buckets[0].key_as_string
                : "";
            let post_item_retweet_name =
              post_item_is_retweet == "true"
                ? i["agg_terms_author_fullname.keyword"].buckets[0].key
                : "";
            let img_post = i["agg_terms_sample_picture_url.keyword"].buckets[0]
              ? i["agg_terms_sample_picture_url.keyword"].buckets[0].key
              : "";
            let avatar =
              i["agg_terms_author_screen_name.keyword"].buckets[0][
                "agg_terms_author_photo.keyword"
              ].buckets[0].key;
            return {
              socialNetwork: "tw",
              userName: profile,
              retweetProfile: post_item_retweet_name,
              is_rtw: post_item_is_retweet,
              userAvatar: avatar,
              postDate: util.convertTimestampToDate(
                i.agg_terms_sample_created_at.buckets[0].key
              ),
              postTime: util.convertTimestampToTime(
                i.agg_terms_sample_created_at.buckets[0].key
              ),
              postcontent: i.key,
              postImage: img_post,
              likeCount:
                i.agg_sum_sample_favorite_count.value !== 0
                  ? util.convertNumberTokFormat(
                      i.agg_sum_sample_favorite_count.value
                    )
                  : 0,
              retweetCount: util.convertNumberTokFormat(
                i.agg_sum_sample_retweet_count.value
              ),
            };
          });
          // console.log('datos de tw-', twModal);
          twModal.map((x) => {
            mdCnt.push(x);
          });
          // ['agg_terms_sample_text.keyword'].buckets
        }
        if (dataFB.length !== 0) {
          fb_post = dataFB;
          fb_post_data = fb_post.map(
            (e) => e["agg_terms_sample_post_text.keyword"].buckets
          );
          fbModal = fb_post_data[0].slice(0, 3).map((d) => {
            let profile = d["agg_terms_sample_post_author.keyword"].buckets[0]
              ? d["agg_terms_sample_post_author.keyword"].buckets[0].key
              : "unknow";
            let post_image = d["agg_terms_sample_post_image.keyword"].buckets[0]
              ? d["agg_terms_sample_post_image.keyword"].buckets[0].key
              : "";
            // console.log('map  wordss',item.key, d);
            return {
              socialNetwork: dataFB[0].key,
              userName: profile,
              userAvatar:
                "https://graph.facebook.com/" +
                profile.replace(/%[0-9A-Fa-f]{2}|\s+/g, '') +
                "/picture?type=square",
              postDate: util.convertTimestampToDate(
                d.agg_terms_sample_post_created_at.buckets[0].key
              ),
              postTime: util.convertTimestampToTime(
                d.agg_terms_sample_post_created_at.buckets[0].key
              ),
              postcontent: d.key,
              postImage: post_image,
              likeCount: util.convertNumberTokFormat(
                d.agg_sum_sample_post_likes_count.value
              ),
              sharedCount: util.convertNumberTokFormat(
                d.agg_sum_sample_post_shares.value
              ),
              comentsCount: util.convertNumberTokFormat(
                d.agg_sum_sample_post_comments_count.value
              ),
              // coments:[
              //     {
              //         date: '06-06-2021 - 11:20',
              //         text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus harum nam officiis a, eum autem? Officiis consectetur quam.'
              //     },
              //     {
              //         date: '06-06-2021 - 11:20',
              //         text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus harum nam officiis a, eum autem? Officiis consectetur quam.'
              //     }
              // ]
            };
          });
          fbModal.map((x) => {
            mdCnt.push(x);
          });
        }    
        if (dataIG.length !== 0) {
          ig_post = dataIG;
          ig_post_data = ig_post.map(
            (e) =>{ 
              // console.log('word algo ', e["agg_terms_sample_text.keyword"].buckets);
              return e["agg_terms_sample_text.keyword"].buckets
            }
            );
            igModal = ig_post_data[0].slice(0, 3).map((d) => {
              let profile = d["agg_terms_author_fullname.keyword"]
              ? d["agg_terms_author_fullname.keyword"].buckets[0].key
              : "unknow";
              let post_image = d["agg_terms_sample_post_text.keyword"].buckets[0]['agg_terms_sample_post_image.keyword'].buckets[0]
              ? d["agg_terms_sample_post_text.keyword"].buckets[0]['agg_terms_sample_post_image.keyword'].buckets[0].key
              : "";
              return {
                socialNetwork: dataIG[0].key,
                userName: profile,
                userAvatar:'',
                //userAvatar:
                //   "https://graph.instagram.com/" +
                //   profile +
                //   "/picture?type=square",
                postDate: util.convertTimestampToDate(
                  d.agg_terms_sample_created_at.buckets[0].key
                ),
                postTime: util.convertTimestampToTime(
                  d.agg_terms_sample_created_at.buckets[0].key
                ),
                postcontent: d.key,
                postImage: post_image,
                likeCount: util.convertNumberTokFormat(
                  d['agg_terms_sample_post_text.keyword'].buckets[0].agg_sum_sample_post_likes_count.value
                ),
                sharedCount: util.convertNumberTokFormat(
                  d['agg_terms_sample_post_text.keyword'].buckets[0].agg_sum_sample_post_shares.value
                ),
                comentsCount: util.convertNumberTokFormat(
                    d['agg_terms_sample_post_text.keyword'].buckets[0].agg_sum_sample_post_comments_count.value
                ),
                // coments:[
                //     {
                //         date: '06-06-2021 - 11:20',
                //         text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus harum nam officiis a, eum autem? Officiis consectetur quam.'
                //     },
                //     {
                //         date: '06-06-2021 - 11:20',
                //         text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus harum nam officiis a, eum autem? Officiis consectetur quam.'
                //     }
                // ]
              };
            });
            igModal.map((x) => {
              mdCnt.push(x);
            });
        }
        if (dataLK.length !== 0) {
          lk_post = dataLK;
          lk_post_data = lk_post.map(
            (e) =>{ 
              return e["agg_terms_sample_text.keyword"].buckets
            }
            );
            lkModal = lk_post_data[0].slice(0, 3).map((d) => {
              let profile = d["agg_terms_author_fullname.keyword"]
              ? d["agg_terms_author_fullname.keyword"].buckets[0].key
              : "unknow";
              let post_image = d["agg_terms_sample_post_text.keyword"].buckets[0]['agg_terms_sample_post_image.keyword'].buckets[0]
              ? d["agg_terms_sample_post_text.keyword"].buckets[0]['agg_terms_sample_post_image.keyword'].buckets[0].key
              : "";
              return {
                socialNetwork: dataLK[0].key,
                userName: profile,
                userAvatar:'',
                //userAvatar:
                //   "https://graph.instagram.com/" +
                //   profile +
                //   "/picture?type=square",
                postDate: util.convertTimestampToDate(
                  d.agg_terms_sample_created_at.buckets[0].key
                ),
                postTime: util.convertTimestampToTime(
                  d.agg_terms_sample_created_at.buckets[0].key
                ),
                postcontent: d.key,
                postImage: post_image,
                likeCount: util.convertNumberTokFormat(
                  d['agg_terms_sample_post_text.keyword'].buckets[0].agg_sum_sample_post_likes_count.value
                ),
                sharedCount: util.convertNumberTokFormat(
                  d['agg_terms_sample_post_text.keyword'].buckets[0].agg_sum_sample_post_shares.value
                ),
                comentsCount: util.convertNumberTokFormat(
                    d['agg_terms_sample_post_text.keyword'].buckets[0].agg_sum_sample_post_comments_count.value
                ),
                // coments:[
                //     {
                //         date: '06-06-2021 - 11:20',
                //         text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus harum nam officiis a, eum autem? Officiis consectetur quam.'
                //     },
                //     {
                //         date: '06-06-2021 - 11:20',
                //         text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus harum nam officiis a, eum autem? Officiis consectetur quam.'
                //     }
                // ]
              };
            });
            lkModal.map((x) => {
              mdCnt.push(x);
            });
        } 
        
        if (dataNWS.length !== 0) {
          nws_post = dataNWS;
          nws_post_data = nws_post.map(
            (e) => e["agg_terms_sample_post_text.keyword"].buckets
          );
          nwsModal = nws_post_data[0].slice(0, 3).map((d) => {
            let profile = d["agg_terms_sample_post_author.keyword"].buckets[0]
              ? d["agg_terms_sample_post_author.keyword"].buckets[0].key
              : "unknown";
            let profilePicture = d["agg_terms_sample_post_author.keyword"].buckets[0]["agg_terms_sample_post_author_photo.keyword"].buckets[0].key
              ? d["agg_terms_sample_post_author.keyword"].buckets[0]["agg_terms_sample_post_author_photo.keyword"].buckets[0].key
              : "unknown";
            let post_image = d["agg_terms_sample_post_image.keyword"].buckets[0]
              ? d["agg_terms_sample_post_image.keyword"].buckets[0].key
              : "";
            // console.log('map  wordss',item.key, d);
            return {
              socialNetwork: dataNWS[0].key,
              userName: profile,
              userAvatar: profilePicture,
              postDate: util.convertTimestampToDate(
                d.agg_terms_sample_post_created_at.buckets[0].key
              ),
              postTime: util.convertTimestampToTime(
                d.agg_terms_sample_post_created_at.buckets[0].key
              ),
              postcontent: d.key,
              postImage: post_image,
              likeCount: util.convertNumberTokFormat(
                d.agg_sum_sample_post_likes_count.value
              ),
              sharedCount: util.convertNumberTokFormat(
                d.agg_sum_sample_post_shares.value
              ),
              comentsCount: util.convertNumberTokFormat(
                d.agg_sum_sample_post_comments_count.value
              ),
            };
          });
          nwsModal.map((x) => {
            mdCnt.push(x);
          });
        }

        return {
          order: index,
          word: item.key,
          interactions: item.agg_sum_sample_interactions.value,
          sentiment: max_sentiment.key,
          channel: netWorkIcons.join(),
          modalcontent: mdCnt,
        };
      });
      let wordsArr = this.wordsDT.map((item, index) => {
        let wordResult = [];
        let order = this.wordsDT.length - item.order;
        let id_ = `word_${index}`;
        item.id = id_;
        if (order < 10) order = 10;
        wordResult.push(item.word, order, item.sentiment, id_);
        // console.log('nube de palabras.', order);
        return wordResult;
      });
      this.cloudWords = [];
      this.cloudWords.push(wordsArr);
    },
    setItemsTable() {
      let arword = this.wordsDT.map((item) => {
        return {
          word: item.word,
          type: item.channel,
          sentiment: item.sentiment,
          interactions: item.interactions,
        };
      });
      this.itemsTable = [];
      this.itemsTable = arword;
      // console.log('table----worrds', this.itemsTable, arword);
    },
  },
  watch: {
    wordsData: {
      immediate: true,
      deep: true,
      handler() {
        this.setWords();
        this.setItemsTable();
      },
    },
  },
};
</script>

<style>
.sentimentword {
  padding: 0 30px !important;
}
.sentimentword__box-gradient {
  position: relative;
  width: 20px;
  background: linear-gradient(
    to bottom,
    rgba(176, 215, 151, 1) 0%,
    rgba(131, 137, 148, 0.85) 50%,
    rgba(227, 125, 125, 0.69) 100%
  );
}
.sentimentword__box-gradient,
.sentimentword__box-gradient_names {
  height: 250px;
}
.sentimentword__table {
  width: 400px;
  height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
}
.sentimentword__table td {
  padding: 0 5px !important;
}
.sentimentword__table i.v-data-table-header__icon {
  position: absolute;
}
.sentimentword__cloud {
  width: 530px;
}
.modal_component {
  top: -10%;
  left: 10%;
  z-index: 1;
}
.sentimentword__cloud__box {
  height: 250px;
  width: 100%;
}
</style>