<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="context-content">
            <button class="
                bgColorPrincipal
                modal-icon-close
                px-2
                py-1
                mr-3
                mt-3
                rounded
              " @click="$emit('close')">
              <v-icon color="#fff" size="16"> fas fa-times </v-icon>
            </button>

            <v-row>
              <v-col>
                <h1 class="font18pt titleColor font-weight-bold">
                  Create context
                </h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="context-content__search">
                <input maxlength="40" type="text" class="inputs pl-3 font13pt titleColor font-weight-bold"
                  placeholder="Context name" v-model="name" />
              </v-col>
            </v-row>
            <v-row>
              <div class="component-content__tabs col">
                <v-tabs centered slider-color="#00A5FF" v-model="model" background-color="#f3f4f7" height="51" grow
                  class="component-content__tabs-menu">
                  <v-tab>
                    <span class="
                        font15pt
                        titleColor
                        font-weight-bold
                        text-capitalize
                        mr-1
                      ">Twitter</span>
                    <span>
                      <v-icon size="16" color="#1da1f2">fab fa-twitter</v-icon>
                    </span>
                  </v-tab>
                  <v-tab>
                    <span class="
                        font15pt
                        titleColor
                        font-weight-bold
                        text-capitalize
                        mr-1
                      ">Facebook</span>
                    <span>
                      <v-icon size="16" color="#598ec2">fab fa-facebook</v-icon>
                    </span>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="model" class="component-content__tabs__info pb-0">
                  <v-tab-item transition="none" reverse-transition="fade-transition">
                    <div class="base_content d-flex justify-center align-center">
                      <CreateTwitterDrivers :twDriversToAdd="twDriversToAdd" @driversToAdd="updateTwDriversToAdd"
                        @deleteDriversToAdd="deleteTwDriversToAdd" :allIscDriversToAdd="allIscDriversToAdd"
                        @iscDriversToAdd="updateIscDriversToAdd" @deleteIscDriversToAdd="deleteIscDriversToAdd"
                        @driverLoading="driverLoading" />
                    </div>
                  </v-tab-item>
                  <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
                    <div class="base_content d-flex justify-center align-center">
                      <CreateFacebookDrivers :fbDriversToAdd="fbDriversToAdd" @driversToAdd="updateFbDriversToAdd"
                        @deleteDriversToAdd="deleteFbDriversToAdd" :allIscDriversToAdd="allIscDriversToAdd"
                        @iscDriversToAdd="updateIscDriversToAdd" @deleteIscDriversToAdd="deleteIscDriversToAdd"
                        @driverLoading="driverLoading" />
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-row>
            <v-row>
              <v-col class="text-right textError">
                {{ warningText }}
                <button class="buttonSave pt-2 pb-2 pl-5 pr-5 ml-3 rounded" v-bind:class="{ buttonDisabled }"
                  @click="saveContext()" :disabled="buttonDisabled">
                  <span class="mr-1 font13pt">{{
                      loading ? "Saving..." : "Save"
                  }}</span>
                  <v-icon color="#fff" size="13" v-if="!loading">
                    fas fa-save
                  </v-icon>
                </button>
              </v-col>
            </v-row>
          </div>
          <div class="prog-bar__createContext">
            <v-progress-linear indeterminate v-if="loading || checkDriverLoading"></v-progress-linear>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CreateTwitterDrivers from "@/components/context/CreateTwitterDrivers.vue";
import CreateFacebookDrivers from "@/components/context/CreateFacebookDrivers.vue";

export default {
  name: "CreateContext",
  props: ["contentModal"],
  components: { CreateTwitterDrivers, CreateFacebookDrivers },
  data() {
    return {
      model: "",
      name: "",
      warningText: "",
      loading: false,
      checkDriverLoading: false,
      ISCDrivers: [],
      buttonDisabled: true,
      twDriversToAdd: [],
      fbDriversToAdd: [],
      allIscDriversToAdd: [],
    };
  },
  created() {
    //this.setISCDrivers();
  },
  methods: {
    saveContext: async function () {
      if (this.name.length <= 0) return;
      if (
        this.twDriversToAdd.length > 0 ||
        this.fbDriversToAdd.length > 0 ||
        this.allIscDriversToAdd.length > 0
      ) {
        this.loading = true;
        const fb_drivers_array = this.fbDriversToAdd.map(
          (driver) => {
            return {
              'active': driver.active,
              'mask': driver.mask,
              'name': driver.name,
              'page_id': driver.page_id,
              'page_url': driver.page_url
            }
          })
        const variablesCreateContext = {
          account_id: sessionStorage.getItem("client_id"),
          name: this.name,
          twitter_drivers:
            this.twDriversToAdd.length > 0 ? this.twDriversToAdd : null,
          facebook_drivers:
            this.fbDriversToAdd.length > 0 ? fb_drivers_array : null,
        };

        try {
          const {
            data: { createContext },
          } = await this.$store.dispatch(
            "overview/createContext",
            variablesCreateContext
          );
          if (createContext.id) {
            if (this.allIscDriversToAdd.length > 0) {
              for (const iscDriver of this.allIscDriversToAdd) {
                await this.$store.dispatch("overview/addGeoKeyword", {
                  context_id: createContext.id,
                  keyword_id: iscDriver.id,
                  type: iscDriver.type,
                });
              }
            }
          } else {
            throw new Error("Error => createContext dispatch");
          }
          this.loading = false;
          this.$emit("close", createContext);
        } catch (error) {
          console.error(error);
          this.showWarning("Something went wrong!");
          return;
        }
      }
    },
    showWarning: function (message) {
      this.warningText = message;
    },
    updateTwDriversToAdd(newDriver) {
      this.twDriversToAdd.push(newDriver);
    },
    updateFbDriversToAdd(newDriver) {
      this.fbDriversToAdd.push(newDriver);
    },
    updateIscDriversToAdd(newDriver) {
      this.allIscDriversToAdd.push(newDriver);
    },
    deleteTwDriversToAdd(driver) {
      const driverIndex = this.twDriversToAdd.findIndex((d) => {
        return d.term == driver.term && d.place_id == driver.place_id;
      });
      this.twDriversToAdd.splice(driverIndex, 1);
      this.validateInputs();
    },
    deleteFbDriversToAdd(driver) {
      const driverIndex = this.fbDriversToAdd.findIndex((d) => {
        return d.mask == driver.mask;
      });
      this.fbDriversToAdd.splice(driverIndex, 1);
      this.validateInputs();
    },
    deleteIscDriversToAdd(driver) {
      const driverIndex = this.allIscDriversToAdd.findIndex((d) => {
        return (
          d.context_id == driver.context_id &&
          d.keyword_id == driver.keyword_id &&
          d.type == driver.type
        );
      });
      this.allIscDriversToAdd.splice(driverIndex, 1);
      this.validateInputs();
    },
    validateInputs() {
      if (
        this.twDriversToAdd.length > 0 ||
        this.fbDriversToAdd.length > 0 ||
        this.allIscDriversToAdd.length > 0
      ) {
        if (this.name.trim().length > 0) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      } else {
        this.buttonDisabled = true;
      }
    },
    driverLoading(value) {
      this.checkDriverLoading = value;
    }
  },
  watch: {
    /* ISCDrivers: {
      deep: true,
      handler: (x) => {
        if (this.ISCDrivers.length > 0) {
          return false;
        } else {
          return true;
        }
      },
    }, */
    twDriversToAdd: {
      immediate: true,
      handler: function () {
        this.validateInputs();
      },
    },
    fbDriversToAdd: {
      immediate: true,
      handler: function () {
        this.validateInputs();
      },
    },
    allIscDriversToAdd: {
      immediate: true,
      handler: function () {
        this.validateInputs();
      },
    },
    name: {
      immediate: true,
      handler: function () {
        if (!this.name) this.showWarning("Please enter a context name");
        else this.showWarning("");
        this.validateInputs();
      },
    },
  },
};
</script>

<style>
.prog-bar__createContext {
  width: 100%;
  bottom: 0px;
  left: 0px;
  position: absolute;
}

.component-content__tabs__info {
  padding: 0px !important;
}
</style>