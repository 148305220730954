<template>
  <v-main class="v-main__influencers">
    <v-row class="mb-5 pb-5">
      <TopInfluencerAccounts :dataInfluncers="influencers" />
    </v-row>
    <v-row class="mt-5">
      <InfluencersRanking :dataInfluncers="influencers" />
    </v-row>
  </v-main>
</template>

<script>
import TopInfluencerAccounts from "./topInfluencerAccounts.vue";
import InfluencersRanking from "./influencersRanking.vue";
import { util } from "@isc/styleguide";

export default {
  name: "InfluencersContent",
  components: { TopInfluencerAccounts, InfluencersRanking },
  props: {
    influencersAccount: Array,
    fbpost: Array,
  },
  data() {
    return {
      influencers: [],
    };
  },
  created() { },
  watch: {
    influencersAccount: {
      immediate: true,
      handler() {
        const influencersList = [];
        const defaultImage =
          "https://listening.isocialcube.com/images/icon-user.png";

        let totalScore = 0;
        this.influencersAccount.map(
          (i) => (totalScore += i.agg_sum_sample_interactions.value)
        );
        this.influencersAccount.map((item) => {
          const communityValue = item.agg_avg_author_followers_count
            ? Math.trunc(item.agg_avg_author_followers_count.value)
            : 0;

          const modalcontent = [];
          if (item["agg_terms_sample_text.keyword"]) {
            item["agg_terms_sample_text.keyword"].buckets.map((i) => {
              modalcontent.push({
                userName: item.key, //i['agg_terms_author_fullname.keyword'] ? i['agg_terms_author_fullname.keyword'].buckets[0].key : '',
                retweetProfile: "AMglmgcfg",
                userAvatar: item["agg_terms_author_photo.keyword"]
                  ? item["agg_terms_author_photo.keyword"].buckets.length ? item["agg_terms_author_photo.keyword"].buckets[0].key : defaultImage
                  : defaultImage, //i['agg_terms_author_fullname_photo.keyword'] ? i['agg_terms_author_fullname_photo.keyword'].buckets[0].key : '',//'https://pbs.twimg.com/profile_images/1254159388656324608/E6Nc_M1u.jpg',
                postDate: i["agg_terms_sample_created_at"]
                  ? util.convertTimestampToDate(
                    i["agg_terms_sample_created_at"].buckets[0].key
                  )
                  : "",
                postTime: i["agg_terms_sample_created_at"]
                  ? util.convertTimestampToTime(
                    i["agg_terms_sample_created_at"].buckets[0].key
                  )
                  : "",
                postcontent: i.key ? i.key : "", //'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio velit mollitia omnis, repellat ex et quod inventore. Nisi, distinctio quos?',
                postImage: i["agg_terms_sample_picture_url.keyword"].buckets[0]
                  ? i["agg_terms_sample_picture_url.keyword"].buckets[0].key
                  : "", //'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
                likeCount: i["agg_sum_sample_favorite_count"].value,
                retweetCount: i["agg_sum_sample_retweet_count"].value,
              });
            });
          }

          const currentSentiment = util.maxFromObjectArray(
            item["agg_terms_sample_sentiment.keyword"].buckets,
            "doc_count"
          );
          const profile = item.key;
          const fbkey = this.fbpost.filter((post) => post.key == item.key);
          influencersList.push({
            idfb: fbkey
              ? fbkey
              : "",
            socialNetwork: item.agg_terms_source_snetwork_id.buckets[0].key,
            userName: item.key,
            userAvatar: "https://graph.facebook.com/" +
                profile.replace(/%[0-9A-Fa-f]{2}|\s+/g, '') +
                "/picture?type=square",
            values: {
              score: totalScore == 0 ? 0 : (
                (item.agg_sum_sample_interactions.value * 100) /
                totalScore
              ).toFixed(2),
              communityAbbr: item.agg_avg_author_followers_count
                ? util.convertNumberTokFormat(communityValue)
                : 0,
              comunity: item.agg_avg_author_followers_count
                ? communityValue
                : 0,
              interactions: item.agg_sum_sample_interactions.value,
              sentiment: currentSentiment.key,
            },
            open: false,
            modalcontent,
          });
        });

        this.influencers = influencersList;
      },
    },
  },
};
</script>

<style>
.v-main__influencers {
  padding: 10px 10px 10px 10px !important;
}
</style>
