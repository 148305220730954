<template>
	<v-container class="pa-0 mt-5">
		<v-row>
			<v-col class="d-flex align-center mb-5">
				<h1 class="font15pt font-weight-bold mr-2">Sentiment By Channel</h1>
				<tooltipComponent :tooltipText="tooltip" />
			</v-col>
		</v-row>
		<v-row class="d-flex justify-center pt-10">
			<div class="d-flex justify-center pl-10 pr-10 mb-10" style="width: 35rem">
				<div class="channel__icon-box bgColorTwitter white--text d-flex justify-center align-center">
					<v-icon color="white" size="50"> fab fa-twitter </v-icon>
				</div>
				<div class="col sentiment_channel_items pl-3 d-flex flex-column justify-space-between pb-0 pt-0">
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Positive </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorpositive sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: tw_data.pos + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ tw_data.pos }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Neutral </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorneutral sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: tw_data.neutral + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ tw_data.neutral }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Negative </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColornegative sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: tw_data.neg + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ tw_data.neg }}%</span
						>
					</div>
				</div>
			</div>
			<div class="d-flex justify-center pl-10 pr-10 mb-10" style="width: 35rem">
				<div class="channel__icon-box bgColorFacebook white--text d-flex justify-center align-center">
					<v-icon color="white" size="50"> fab fa-facebook </v-icon>
				</div>
				<div class="col sentiment_channel_items pl-3 d-flex flex-column justify-space-between pb-0 pt-0">
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Positive </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorpositive sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: fb_data.pos + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ fb_data.pos }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Neutral </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorneutral sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: fb_data.neutral + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ fb_data.neutral }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Negative </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColornegative sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: fb_data.neg + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ fb_data.neg }}%</span
						>
					</div>
				</div>
			</div>
			<div class="d-flex justify-center pl-10 pr-10 mb-10" style="width: 35rem">
				<div class="channel__icon-box bgColorInstagram white--text d-flex justify-center align-center">
					<v-icon color="white" size="50"> fab fa-instagram </v-icon>
				</div>
				<div class="col sentiment_channel_items pl-3 d-flex flex-column justify-space-between pb-0 pt-0">
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Positive </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorpositive sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: ig_data.pos + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ ig_data.pos }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Neutral </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorneutral sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: ig_data.neutral + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ ig_data.neutral }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Negative </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColornegative sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: ig_data.neg + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ ig_data.neg }}%</span
						>
					</div>
				</div>
			</div>
			<div class="d-flex justify-center pl-10 pr-10 mb-10" style="width: 35rem">
				<div class="channel__icon-box bgColorLinkedin white--text d-flex justify-center align-center">
					<v-icon color="white" size="50"> fab fa-linkedin </v-icon>
				</div>
				<div class="col sentiment_channel_items pl-3 d-flex flex-column justify-space-between pb-0 pt-0">
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Positive </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorpositive sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: lk_data.pos + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ lk_data.pos }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Neutral </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorneutral sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: lk_data.neutral + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ lk_data.neutral }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Negative </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColornegative sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: lk_data.neg + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ lk_data.neg }}%</span
						>
					</div>
				</div>
			</div>
			<!-- <div class="d-flex justify-center pl-10 pr-10 mb-10" style="width: 35rem">
				<div class="channel__icon-box bgColorNewspapers white--text d-flex justify-center align-center">
					<v-icon size="50" color="white">fa-solid fa-newspaper</v-icon>
				</div>
				<div class="col sentiment_channel_items pl-3 d-flex flex-column justify-space-between pb-0 pt-0">
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Positive </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorpositive sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: nws_data.pos + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ nws_data.pos }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Neutral </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColorneutral sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: nws_data.neutral + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ nws_data.neutral }}%</span
						>
					</div>
					<div class="sentimentchannel__content__item__value d-flex align-center">
						<div class="" style="min-width: 5em">
							<span class="parColor font12pt"> Negative </span>
						</div>
						<div class="sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0">
							<div
								class="bgColornegative sentiment__box__item__value__perc--color position-absolute"
								:style="{ width: nws_data.neg + '%' }"
							></div>
						</div>
						<span class="pl-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
							>{{ nws_data.neg }}%</span
						>
					</div>
				</div>
			</div> -->
		</v-row>
	</v-container>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
export default {
	name: "SentimentByChannel",
	components: { tooltipComponent },
	props: {
		networks: Object,
	},
	data() {
		return {
			tooltip: "Sentiment by Social Network.",
			fb_data: {
				pos: 0,
				neutral: 0,
				neg: 0,
			},
			tw_data: {
				pos: 0,
				neutral: 0,
				neg: 0,
			},
			ig_data: {
				pos: 0,
				neutral: 0,
				neg: 0,
			},
			lk_data: {
				pos: 0,
				neutral: 0,
				neg: 0,
			},
			nws_data: {
				pos: 0,
				neutral: 0,
				neg: 0,
			},
		};
	},
	created() {},
	watch: {
		networks: {
			immediate: true,
			deep: true,
			handler: function () {
				let tw_data = this.networks.buckets.find((item) => item.key == "tw");
				let fb_data = this.networks.buckets.find((item) => item.key == "fb");
				let ig_data = this.networks.buckets.find((item) => item.key == "ig");
				let lk_data = this.networks.buckets.find((item) => item.key == "lk");
				let nws_data = this.networks.buckets.find((item) => item.key == "nws");

				if (tw_data) {
					let count = tw_data.doc_count;
					let data = tw_data["agg_terms_sample_sentiment.keyword"].buckets;
					let pos = data.find((item) => item.key == "pos");
					let neg = data.find((item) => item.key == "neg");
					let neutral = data.find((item) => item.key == "neutral");
					this.tw_data.pos = pos ? Math.round((pos.doc_count / count) * 100) : 0;
					this.tw_data.neg = neg ? Math.round((neg.doc_count / count) * 100) : 0;
					this.tw_data.neutral = neutral ? Math.round((neutral.doc_count / count) * 100) : 0;

					// Adjust for rounding errors (if summed percentages are not 100%)
					const adjust = 100 - this.tw_data.pos - this.tw_data.neg - this.tw_data.neutral;
					if (adjust !== 0) {
						if (this.tw_data.pos >= this.tw_data.neg && this.tw_data.pos >= this.tw_data.neutral) {
							this.tw_data.pos += adjust;
						} else if (this.tw_data.neg >= this.tw_data.pos && this.tw_data.neg >= this.tw_data.neutral) {
							this.tw_data.neg += adjust;
						} else {
							this.tw_data.neutral += adjust;
						}
					}
				} else {
					this.tw_data.pos = 0; //Math.round((0 / count) * 100);
					this.tw_data.neg = 0; //Math.round((0 / count) * 100);
					this.tw_data.neutral = 0; //Math.round((0 / count) * 100);
				}

				if (fb_data) {
					let count = fb_data.doc_count;
					let data = fb_data["agg_terms_sample_sentiment.keyword"].buckets;
					let pos = data.find((item) => item.key == "pos");
					let neg = data.find((item) => item.key == "neg");
					let neutral = data.find((item) => item.key == "neutral");
					this.fb_data.pos = pos ? Math.round((pos.doc_count / count) * 100) : 0;
					this.fb_data.neg = neg ? Math.round((neg.doc_count / count) * 100) : 0;
					this.fb_data.neutral = neutral ? Math.round((neutral.doc_count / count) * 100) : 0;

					// Adjust for rounding errors (if summed percentages are not 100%)
					const adjust = 100 - this.fb_data.pos - this.fb_data.neg - this.fb_data.neutral;
					if (adjust !== 0) {
						if (this.fb_data.pos >= this.fb_data.neg && this.fb_data.pos >= this.fb_data.neutral) {
							this.fb_data.pos += adjust;
						} else if (this.fb_data.neg >= this.fb_data.pos && this.fb_data.neg >= this.fb_data.neutral) {
							this.fb_data.neg += adjust;
						} else {
							this.fb_data.neutral += adjust;
						}
					}
				} else {
					this.fb_data.pos = 0; //Math.round((0 / count) * 100);
					this.fb_data.neg = 0; //Math.round((0 / count) * 100);
					this.fb_data.neutral = 0; //Math.round((0 / count) * 100);
				}

				if (ig_data) {
					let count = ig_data.doc_count;
					let data = ig_data["agg_terms_sample_sentiment.keyword"].buckets;
					let pos = data.find((item) => item.key == "pos");
					let neg = data.find((item) => item.key == "neg");
					let neutral = data.find((item) => item.key == "neutral");
					this.ig_data.pos = pos ? Math.round((pos.doc_count / count) * 100) : 0;
					this.ig_data.neg = neg ? Math.round((neg.doc_count / count) * 100) : 0;
					this.ig_data.neutral = neutral ? Math.round((neutral.doc_count / count) * 100) : 0;

					// Adjust for rounding errors (if summed percentages are not 100%)
					const adjust = 100 - this.ig_data.pos - this.ig_data.neg - this.ig_data.neutral;
					if (adjust !== 0) {
						if (this.ig_data.pos >= this.ig_data.neg && this.ig_data.pos >= this.ig_data.neutral) {
							this.ig_data.pos += adjust;
						} else if (this.ig_data.neg >= this.ig_data.pos && this.ig_data.neg >= this.ig_data.neutral) {
							this.ig_data.neg += adjust;
						} else {
							this.ig_data.neutral += adjust;
						}
					}
				} else {
					this.ig_data.pos = 0; //Math.round((0 / count) * 100);
					this.ig_data.neg = 0; //Math.round((0 / count) * 100);
					this.ig_data.neutral = 0; //Math.round((0 / count) * 100);
				}

				if (lk_data) {
					let count = lk_data.doc_count;
					let data = lk_data["agg_terms_sample_sentiment.keyword"].buckets;
					let pos = data.find((item) => item.key == "pos");
					let neg = data.find((item) => item.key == "neg");
					let neutral = data.find((item) => item.key == "neutral");
					this.lk_data.pos = pos ? Math.round((pos.doc_count / count) * 100) : 0;
					this.lk_data.neg = neg ? Math.round((neg.doc_count / count) * 100) : 0;
					this.lk_data.neutral = neutral ? Math.round((neutral.doc_count / count) * 100) : 0;

					// Adjust for rounding errors (if summed percentages are not 100%)
					const adjust = 100 - this.lk_data.pos - this.lk_data.neg - this.lk_data.neutral;
					if (adjust !== 0) {
						if (this.lk_data.pos >= this.lk_data.neg && this.lk_data.pos >= this.lk_data.neutral) {
							this.lk_data.pos += adjust;
						} else if (this.lk_data.neg >= this.lk_data.pos && this.lk_data.neg >= this.lk_data.neutral) {
							this.lk_data.neg += adjust;
						} else {
							this.lk_data.neutral += adjust;
						}
					}
				} else {
					this.lk_data.pos = 0; //Math.round((0 / count) * 100);
					this.lk_data.neg = 0; //Math.round((0 / count) * 100);
					this.lk_data.neutral = 0; //Math.round((0 / count) * 100);
				}

				if (nws_data) {
					let count = nws_data.doc_count;
					let data = nws_data["agg_terms_sample_sentiment.keyword"].buckets;
					let pos = data.find((item) => item.key == "pos");
					let neg = data.find((item) => item.key == "neg");
					let neutral = data.find((item) => item.key == "neutral");
					this.nws_data.pos = pos ? Math.round((pos.doc_count / count) * 100) : 0;
					this.nws_data.neg = neg ? Math.round((neg.doc_count / count) * 100) : 0;
					this.nws_data.neutral = neutral ? Math.round((neutral.doc_count / count) * 100) : 0;

					// Adjust for rounding errors (if summed percentages are not 100%)
					const adjust = 100 - this.nws_data.pos - this.nws_data.neg - this.nws_data.neutral;
					if (adjust !== 0) {
						if (this.nws_data.pos >= this.nws_data.neg && this.nws_data.pos >= this.nws_data.neutral) {
							this.nws_data.pos += adjust;
						} else if (this.nws_data.neg >= this.nws_data.pos && this.nws_data.neg >= this.nws_data.neutral) {
							this.nws_data.neg += adjust;
						} else {
							this.nws_data.neutral += adjust;
						}
					}
				} else {
					this.nws_data.pos = 0; //Math.round((0 / count) * 100);
					this.nws_data.neg = 0; //Math.round((0 / count) * 100);
					this.nws_data.neutral = 0; //Math.round((0 / count) * 100);
				}
			},
		},
	},
};
</script>

<style>
.channel__icon-box {
	width: 102px;
	height: 102px;
	border-radius: 5px;
}
</style>
